<template>
    <div>
        <!-- calculate -->
        <div v-if="option === null" class="pt-1">
            <p class="text-right p-0 m-0" style="font-size: smaller"
               v-html="$n(pas[json_key + 'p'], 'decimal_2', __getVueCurrencyLocale) + ' %'"></p>
            <p class="text-right pt-2 m-0" style="font-size: smaller"
               v-html="$n(pas[json_key + 'c'], 'currency', __getVueCurrencyLocale)"></p>
        </div>
        <!-- input percent -->
        <div v-else-if="option === 1">
            <div class="d-flex justify-content-around">
                <b-input-group
                    append="%"
                    size="sm"
                >
                    <b-form-input
                        v-model.number="pas[json_key + 'p']"
                        class="text-right no-spinners"
                        size="sm"
                        variant="primary"
                        type="number"
                        @keypress="__onlyDecimalKey($event)"
                    ></b-form-input>
                </b-input-group>
            </div>
            <p class="text-right pt-1 m-0" style="font-size: smaller"
               v-html="$n(pas[json_key + 'c'], 'currency', __getVueCurrencyLocale)"
            ></p>
        </div>
        <!-- input currency -->
        <div v-else-if="option === 2">
            <p class="text-right pt-1 m-0" style="font-size: smaller"
               v-html="$n(pas[json_key + 'p'], 'decimal_2', 'sl-SI') + ' %'"
            ></p>
<!--               v-html="$n(pas[json_key + 'p'], 'decimal', __getVueCurrencyLocale) + ' %'"-->
            <div class="d-flex justify-content-around">
                <b-input-group
                    :append="!currency_prepend ? currency : null"
                    :prepend="currency_prepend ? currency : null"
                    size="sm"
                >
                    <b-form-input
                        v-model.number="pas[json_key + 'c']"
                        class="text-right no-spinners"
                        size="sm"
                        variant="primary"
                        type="number"
                        @keypress="__onlyDecimalKey($event)"
                    ></b-form-input>
                </b-input-group>
            </div>
        </div>
    </div>
</template>

<script>
export default {
name: "FormProductSaleCommission",
        components: {},
    props: {
        title: {
            type: String,
            default: null
        },
        currency_prepend: {
            type: Boolean,
            default: false
        },
        currency: {
            type: String,
            default: null
        },
        option: {
            type: Number,
            default: null
        },
        pas: null,
        json_key: {
            type: String,
            default: null
        },
    },
        data() {
            return {}
        },
        mounted() {},
        methods: {},
        computed: {},
        filters: {},
        watch: {}
}
</script>

<style scoped>

</style>
