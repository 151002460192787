<template>
    <div>
        <b-overlay :opacity="overlay.opacity" :show="overlay.visible" rounded="sm">
            <b-container class="mt-3" fluid>
                <!-- table toolbar -->
                <table-toolbar
                    :columns-filter="tableToolbarColumns"
                    :columns-show="tableToolbarColumns"
                    :locale-key="localeKey"
                    :options="tableToolbar.options"
                    :response.sync="tableToolbar.response"
                    @eventNew="toolbarTableEventNew"
                    @eventRefresh="toolbarTableEventRefresh"
                ></table-toolbar>
                <!-- table -->
                <b-table
                    id="main-table"
                    :current-page="tableToolbar.options.pagination.currentPage"
                    :fields="tableColumns"
                    :filter="tableToolbar.response.filter === undefined ? null : tableToolbar.response.filter.search"
                    :filterIncludedFields="tableToolbar.response.filter === undefined ? null : tableToolbar.response.filter.columnsOn"
                    :items="tableItems"
                    :per-page="tableToolbar.options.pagination.perPage"
                    :sort-by.sync="table.sortBy"
                    :sort-desc.sync="table.sortDesc"
                    bordered head-row-variant="dark" hover outlined
                    responsive
                    striped
                    table-variant="light"
                    thead-class="text-center"
                    @filtered="onFiltered"
                >
                    <!-- id -->
                    <template v-slot:cell(id)="row">
                        <div class="d-flex justify-content-center">
                            {{row.item.id}}
                        </div>
                    </template>
                    <!-- order -->
                    <template v-slot:cell(order)="row">
                        <div class="d-flex justify-content-center">
                            {{row.item.order}}
                        </div>
                    </template>
                    <!-- active -->
                    <template v-slot:cell(active)="row">
                        <div class="d-flex justify-content-around">
                            <b-icon-sun
                                :variant="row.item.active ? 'success' : 'danger'"
                                scale="1.2"
                            ></b-icon-sun>
                        </div>
                    </template>
                    <!-- actions -->
                    <template v-slot:cell(action)="row">
                        <div class="d-flex justify-content-around">
                            <!--                            &lt;!&ndash; detail show &ndash;&gt;
                                                        <b-icon-caret-down-square
                                                            v-if="!row.detailsShowing"
                                                            v-b-tooltip.hover
                                                            :title="$t('form.detail_show')"
                                                            scale="1.2"
                                                            style="cursor: pointer; margin-right: 3px;"
                                                            variant="secondary"
                                                            @click="row.toggleDetails"
                                                        ></b-icon-caret-down-square>
                                                        &lt;!&ndash; detail hide &ndash;&gt;
                                                        <b-icon-caret-up-square-fill
                                                            v-if="row.detailsShowing"
                                                            v-b-tooltip.hover
                                                            :title="$t('form.detail_hide')"
                                                            scale="1.2"
                                                            style="cursor: pointer; margin-right: 3px;"
                                                            variant="secondary"
                                                            @click="row.toggleDetails"
                                                        ></b-icon-caret-up-square-fill>-->
                            <!-- edit -->
                            <b-icon-file-text
                                v-if="!row.item.system"
                                v-b-tooltip.hover
                                :title="$t('form.edit')"
                                scale="1.2"
                                style="cursor: pointer; margin-right: 3px;"
                                variant="secondary"
                                @click="recordEdit(row.item)"
                            ></b-icon-file-text>
                            <!-- delete -->
                            <b-icon-trash
                                v-if="!row.item.system"
                                v-b-tooltip.hover
                                :title="$t('form.delete')"
                                scale="1.2"
                                style="cursor: pointer;"
                                variant="danger"
                                @click="recordDelete(row.item)"
                            ></b-icon-trash>
                        </div>
                    </template>
                    <!-- row detail -->
                    <template v-slot:row-details="row">
                        <show-admin-organisation
                            :content="tableRowDetail(row.item.id)"
                            :data="data.data"
                        ></show-admin-organisation>
                    </template>
                </b-table>
                <!-- pagination -->
                <b-pagination
                    v-model="tableToolbar.options.pagination.currentPage"
                    :per-page="tableToolbar.options.pagination.perPage"
                    :total-rows="this.tableToolbar.options.filterCount"
                    aria-controls="main-table"
                    limit="20"
                    size="sm"
                ></b-pagination>
            </b-container>
        </b-overlay>
        <!-- modalFormXxx -->
        <modal-form-product
            :form="form"
            :is-tenant-taxpayer="isTenantTaxpayer"
            :locale-key="localeKey"
            :modal-bus.sync="modalBus"
            :uri="uri"
            @refresh="modalEventRefresh"
        ></modal-form-product>
    </div>
</template>

<script>
import Vue from "vue";
import TableToolbar from "@/components/general/elements/tableAccessories/TableToolbar";
import ModalFormProduct from "@/components/trade/product/ModalFormProduct";
import ShowAdminOrganisation from "@/components/admin/organisation/ShowAdminOrganisation";

export default {
    name: "Products",
    components: {ShowAdminOrganisation, ModalFormProduct, TableToolbar},
    props: {},
    data() {
        return {
            overlay: {
                visible: false,
                opacity: 0.7,
            },
            localeKey: 'trade.product.main',
            uri: {
                main: `api/v1/trade/products`,
                uri1: `api/v1/trade/product`,
            },
            table: {
                items: [],
                fields: [],
                columns: [
                    // <-- product -->
                    {
                        key: 'id',
                        label: this.$t('form.id'),
                        sortable: true,
                        show: true,
                    },
                    {
                        key: 'name',
                        label: this.$tc('form.name'),
                        sortable: true,
                        alwaysShow: true,
                    },
                    {
                        key: 'supplier',
                        label: this.$tc('trade.product.supplier'),
                        sortable: true,
                        show: true,
                        alwaysShow: false,
                    },
                    {
                        key: 'type',
                        label: this.$tc('trade.product_type.main', 0),
                        sortable: true,
                        show: false,
                        alwaysShow: false,
                    },
                    {
                        key: 'group',
                        label: this.$tc('trade.product_group.main', 0),
                        sortable: true,
                        show: false,
                        alwaysShow: false,
                    },
                    {
                        key: 'offer_type',
                        label: this.$t('trade.product.offer'),
                        sortable: true,
                        show: false,
                        alwaysShow: false,
                    },
                    {
                        key: 'production_type',
                        label: this.$t('trade.product.production_type'),
                        sortable: true,
                        show: false,
                        alwaysShow: false,
                    },
                    {
                        key: 'tax_rate',
                        label: this.$tc('register.tax_rate', 1),
                        sortable: true,
                        alwaysShow: false,
                    },
                    {
                        key: 'year',
                        label: this.$t('trade.product.production_year'),
                        sortable: true,
                        show: false,
                        alwaysShow: false,
                    },
                    // {
                    //     key: 'barcode',
                    //     label: this.$t('trade.product.barcode'),
                    //     sortable: true,
                    //     show: true,
                    //     alwaysShow: false,
                    // },
                    // {
                    //     key: 'lot',
                    //     label: this.$t('trade.product.lot'),
                    //     sortable: true,
                    //     show: true,
                    //     alwaysShow: false,
                    // },
                    {
                        key: 'requirement',
                        label: this.$tc('register.requirement', 0),
                        sortable: true,
                        show: true,
                        alwaysShow: false,
                    },
                    // <--  -->
                    {
                        key: 'active',
                        label: this.$t('form.active'),
                        sortable: true,
                        show: false,
                    },
                ],
                sortBy: 'id',
                sortDesc: false,
            },
            tableToolbar: {
                options: {
                    filterCount: 0,
                    rowsCount: 0,
                    pagination: {
                        perPage: 10,
                        currentPage: 1,
                    },
                },
                response: {}
            },
            form: {},
            // <-- set -->
            data: [],
            modalBus: new Vue(),
        }
    },
    mounted() {
        this.baseSelect();
    },
    methods: {
        showOverlay(show) {
            this.overlay.visible = show;
        },
        // <--  -->
        getSupplier(org) {
            if (org === null) {
                return null;
            }
            return this.__isNull(org.name.name) ? org.name.full_name : org.name.name;
        },
        getType(type) {
            if (this.__isNull(type)) {
                return null;
            }
            // <--  -->
            let string = type.meta.name === null || type.meta.name.length === 0 ? null : type.meta.name;
            if (string === null && this.__isNull(type.meta.full_name)) {
                string = type.meta.full_name;
            }
            // <-- locale -->
            if (type.locale[this.$i18n.locale] !== undefined && type.locale[this.$i18n.locale].name !== undefined) {
                if (type.locale[this.$i18n.locale].name !== null && type.locale[this.$i18n.locale].name.length > 0) {
                    string = type.locale[this.$i18n.locale].name;
                }
            }
            // <--  -->
            return string;
        },
        getGroup(type) {
            if (type === null) {
                return null;
            }
            let string = null;
            // <-- get group from data -->
            let data = this.data.data.product_groups;

            let group = data.find((n) => {
                if (n.id === type.product_group_id) {
                    return n;
                }
            });
            if (group === null) {
                return null;
            }
            // <-- group name -->
            string = group.meta.name === null || group.meta.name.length === 0 ? null : group.meta.name;
            if (string === null && group.meta.full_name !== null && group.meta.full_name.length < 0) {
                string = group.meta.full_name;
            }
            // <-- locale -->
            if (group.locale[this.$i18n.locale] !== undefined && group.locale[this.$i18n.locale].name !== undefined) {
                if (group.locale[this.$i18n.locale].name !== null && group.locale[this.$i18n.locale].name.length > 0) {
                    string = group.locale[this.$i18n.locale].name;
                }
            }
            // <--  -->
            return string;
        },
        getOfferType(type) {
            if (type === null) {
                return null;
            }
            // <--  -->
            let string = type.meta.name === null || type.meta.name.length === 0 ? null : type.meta.name;
            if (string === null && this.__isNull(type.meta.full_name)) {
                string = type.meta.full_name;
            }
            // <-- locale -->
            if (type.locale[this.$i18n.locale] !== undefined && type.locale[this.$i18n.locale].name !== undefined) {
                if (type.locale[this.$i18n.locale].name !== null && type.locale[this.$i18n.locale].name.length > 0) {
                    string = type.locale[this.$i18n.locale].name;
                }
            }
            // <--  -->
            return string;
        },
        getProductionType(type) {
            if (type === null) {
                return null;
            }
            // <--  -->
            let string = type.meta.name === null || type.meta.name.length === 0 ? null : type.meta.name;
            if (string === null && this.__isNull(type.meta.full_name)) {
                string = type.meta.full_name;
            }
            // <-- locale -->
            if (type.locale[this.$i18n.locale] !== undefined && type.locale[this.$i18n.locale].name !== undefined) {
                if (type.locale[this.$i18n.locale].name !== null && type.locale[this.$i18n.locale].name.length > 0) {
                    string = type.locale[this.$i18n.locale].name;
                }
            }
            // <--  -->
            return string;
        },
        getTaxRate(rate) {
            if (rate === null) {
                return null;
            }
            // <--  -->
            let string = rate.meta.name === null || rate.meta.name.length === 0 ? null : rate.meta.name;
            if (string === null && this.__isNull(rate.meta.full_name)) {
                string = rate.meta.full_name;
            }
            // <-- locale -->
            if (rate.locale[this.$i18n.locale] !== undefined && rate.locale[this.$i18n.locale].name !== undefined) {
                if (rate.locale[this.$i18n.locale].name !== null && rate.locale[this.$i18n.locale].name.length > 0) {
                    string = rate.locale[this.$i18n.locale].name;
                }
            }
            // <--  -->
            return string;
        },
        getContent(content, key) {
            if (this.__isNull(content) || this.__isNull(content[key])) {
                return null;
            }
            // <--  -->
            return content[key];
        },
        getRequirement(requirement_id) {
            if (this.__isNull(requirement_id) || this.__isNull(this.data.data.requirements)) {
                return null;
            }
            // <--  -->
            let requirements = this.data.data.requirements;
            let string = null;
            requirements.find((n) => {
                if (n.id === requirement_id) {
                    string = n.meta.name;
                    let localeTemp = this.__getJsonLocaleValue(n.locale, 'name');
                    string = localeTemp === null ? string : localeTemp;
                }
            });
            // <--  -->
            return string;
        },
        getContentRequirement(requirement_id, content) {
            let string = '';
            // <-- requirement -->
            let requirement = this.getRequirement(requirement_id);
            string += requirement === null ? '' : requirement;
            // <-- content -->
            if (!this.__isNull(content)) {
                // <-- alcohol_containing -->
                if (content.alcohol_containing !== undefined && content.alcohol_containing > 0) {
                    string += string.length > 0 ? ', ' : '';
                    string += content.alcohol_containing === null ? '' : ' ' + this.__getDecimalLocale(content.alcohol_containing) + ' ' + this.$tc('trade.product.alcohol_containing_unit', 0);
                }
                // <-- legal_alcohol_age -->
                if (content.legal_alcohol_age !== undefined && content.legal_alcohol_age) {
                    string += ', ' + this.$tc('trade.product.minimal_legal_age', 0);
                }
            }
            // <--  -->
            return string.length > 0 ? string : null;
        },
        // <-- toolbarTable -->
        toolbarTableEventRefresh() {
            this.baseSelect();
        },
        toolbarTableEventNew() {
            this.recordNew();
        },
        // <-- modalForm -->
        modalEventRefresh(data) {
            this.$set(this, 'data', data);
        },
        // <-- table -->
        onFiltered(filteredItems) {
            this.tableToolbar.options.filterCount = filteredItems.length;
            this.tableToolbar.options.pagination.currentPage = 1;
        },
        tableRowDetail(id) {
            return this.data.records.find((n) => {
                if (n.id === id) {
                    if (n.post_office !== null && n.post_office[0] !== undefined && n.id === id) {
                        n.state = this.__getStateObject(this.data.data.states, n.post_office[0].state_id);
                    }
                    return n;
                }
            });
        },
        // <-- record -->
        recordNew() {
            this.modalBus.$emit('modalOpen', 0);
        },
        recordEdit(row) {
            this.modalBus.$emit('modalOpen', row.id);
        },
        recordDelete(row) {
            const h = this.$createElement;
            const messageHTML = h('div', [
                h('span', [
                    `${this.$t('form.delete_message.pre')} ${this.$tc(this.localeKey, 2)} `,
                    h('strong', row.name), this.$t('form.delete_message.post'),
                ])
            ]);
            // <-- message box -->
            this.$bvModal.msgBoxConfirm([messageHTML], {
                title: this.$t('form.please_confirm'),
                // size: 'sm',
                // buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: this.$t('form.delete'),
                cancelTitle: this.$t('form.cancel'),
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    if (value) {
                        this.baseDelete(row.id);
                    }
                })
                .catch(err => {
                    // on error executed
                })
        },
        // <-- api calls -->
        baseSelect: function () {
            this.showOverlay(true);
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <-- axios -->
            this.axios.get(this.uri.main, config)
                .then((response) => {
                    this.$set(this, 'data', response.data);
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // console.log("ERROR:", error.response.data);
                });
        },
        baseDelete(record_id) {
            this.showOverlay(true);
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <-- axios -->
            this.axios.delete(this.uri.main + `/${record_id}`, config)
                .then((response) => {
                    this.data = response.data;
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // console.log("error:", error);
                })
                .finally(function () {
                    // always executed
                });
        },
    },
    computed: {
        // <-- tableToolbar -->
        tableToolbarColumns() {
            return this.__getTableToolbarColumns(this.table.columns);
        },
        // <-- table -->
        tableColumns() {
            let columns = this.table.columns;
            let columnsShow = this.tableToolbar.response.columnsShow;
            // <-- check if exists -->
            if (columns === undefined) {
                return [];
            }
            // <--  -->
            let values = [];
            columns.forEach(column => {
                if (column.alwaysShow || (columnsShow != null && columnsShow.indexOf(column.key) >= 0)) {
                    values.push({
                        key: column.key,
                        label: column.label,
                        formatter: column.formatter,
                        sortable: column.sortable,
                        show: column.show,
                    });
                }
            });
            // <-- add column actions -->
            values.push({
                key: 'action',
                label: '',
                id: 0,
                sortable: false,
                thStyle: {width: '60px'}    // 90px for 3 icons
                // variant: 'danger',
            });
            // <--  -->
            return values;
        },
        tableItems() {
            if (this.data.records === undefined) {
                return [];
            }
            // <--  -->
            let records = this.data.records;
            // <-- check if exists -->
            if (records.length === 0) {
                return [];
            }
            // <--  -->
            let items = [];
            let item = {};
            let name;
            records.forEach(record => {
                name = record.meta.name;
                Object.keys(record.locale).find((n) => {
                    if (n === this.$i18n.locale) {
                        if (!this.__isNull(record.locale[n].name)) {
                            name = record.locale[n].name;
                        }
                    }
                });
                // <-- set item -->
                item = {
                    id: record.id,
                    name: name,
                    supplier: this.getSupplier(record.organisation),
                    type: this.getType(record.product_type),
                    group: this.getGroup(record.product_type),
                    offer_type: this.getOfferType(record.offer_type),
                    production_type: this.getProductionType(record.production_type),
                    tax_rate: this.getTaxRate(record.tax_rate),
                    // <-- content -->
                    year: this.getContent(record.content, 'production_year'),
                    requirement: this.getContentRequirement(record.requirement_id, record.content),

                    active: record.active,
                }
                items.push(item);
            });
            // <--  -->
            this.tableToolbar.options.filterCount = items.length;
            this.tableToolbar.options.rowsCount = items.length;
            return items;
        },
        // <--  -->
        isTenantTaxpayer() {
            return false;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
