<template>
    <div>
        <!-- supplier is not selected -->
        <div v-if="!organisation_id > 0">
            {{$t('trade.product.supplier_not_selected')}}
        </div>
        <!-- supplier does not have certificates -->
        <div v-else-if="tableItems === null">
            {{$t('trade.product.supplier_no_certificates')}}
        </div>
        <!-- certificates table -->
        <b-table
            v-else
            :fields="tableFields"
            :items="tableItems"
            bordered
            head-row-variant="primary"
            hover
            outlined
            sticky-header="330px"
            table-class="text-center"
            tbody-class="text-left"
        >
            <!-- actions -->
            <template v-slot:cell(action)="row">
                <div class="d-flex justify-content-center align-items-center">
                    <!-- select -->
                    <b-form-checkbox
                        :id="'select' + row.item.id"
                        v-model="row.item.select"
                        @change="selectChange(row.item)"
                    >
                    </b-form-checkbox>
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
export default {
    name: "FormTableCertificateSelect",
    components: {},
    props: {
        organisation_id: {
            type: Number,
            default: 0
        },
        record: null,
        certificates: {
            type: Array,
            default() {
                return []
            }
        },
    },
    data() {
        return {
            table: {
                items: [],
                fields: [
                    /*                    {
                                            key: 'id',
                                            label: this.$t('form.id'),
                                            sortable: true,
                                            show: true,
                                            thStyle: {'vertical-align': 'middle'}
                                        },*/
                    // {
                    //     key: 'state_id',
                    //     label: this.$t('organisation.state'),
                    //     sortable: true,
                    //     show: true,
                    //     thStyle: {'vertical-align': 'middle'}
                    // },
                    {
                        key: 'org_name',
                        label: this.$t('organisation.certificate_organisation'),
                        sortable: true,
                        show: true,
                        thStyle: {'vertical-align': 'middle'}
                    },
                    {
                        key: 'name',
                        label: this.$t('organisation.certificate_name'),
                        sortable: true,
                        show: true,
                        thStyle: {'vertical-align': 'middle'}
                    },
                    {
                        key: 'number',
                        label: this.$t('organisation.certificate_number'),
                        sortable: true,
                        show: true,
                        thStyle: {'vertical-align': 'middle'}
                    },
                    {
                        key: 'expires',
                        label: this.$t('organisation.certificate_expires'),
                        formatter: (value, key, item) => {
                            return this.__convertBaseDateToLocaleDateString(value)
                        },
                        sortable: true,
                        show: true,
                        thStyle: {'vertical-align': 'middle', width: '130px'}
                    },
                    {
                        key: 'tag',
                        label: this.$t('form.type'),
                        sortable: true,
                        show: true,
                        thStyle: {'vertical-align': 'middle'}
                    },
                    {
                        key: 'action',
                        label: '',
                        sortable: false,
                        thStyle: {width: '40px'}
                    },
                ],
            },
        }
    },
    mounted() {
    },
    methods: {
        selectChange(item) {
            this.$set(this.record, item.id, item.select);
        },
    },
    computed: {
        tableFields() {
            let fields = [];
            this.table.fields.forEach(field => {
                fields.push(field);
            });
            return fields;
        },
        tableItems: function () {
            if (this.record === null || this.certificates === null) {
                return;
            }
            // <-- set items -->
            let items = [];
            let tag;
            this.certificates.forEach(item => {
                    tag = null;
                    if (item.tag_id > 0) {
                        // <-- tag -->
                        if (item.tag !== null) {
                            tag = item.tag.meta.name;
                            if (item.tag.locale[this.$i18n.locale] !== undefined) {
                                if (!this.__isNull(item.tag.locale[this.$i18n.locale].name)) {
                                    tag = item.tag.locale[this.$i18n.locale].name;
                                }
                            }
                        }
                        // <-- push -->
                        items.push(
                            {
                                // key: this.__createUniqueKey(index),
                                // index: index,
                                id: item.id,
                                // state_id: item.state_id,
                                org_name: item.org_name,
                                number: item.number,
                                name: item.name,
                                expires: item.expires,
                                tag: tag,
                                select: this.record[item.id] !== undefined ? this.record[item.id] : false,
                            }
                        );
                    }
                }
            );
            // <--  -->
            if (items.length > 0) {
                this.$set(this.table, 'items', items);
                return items;
            }
            return null;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
