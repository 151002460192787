<template>
    <div>
        <!-- calculate -->
        <div v-if="option === null" class="pt-2">
            <p :title="title + ' ' + $t('finance.without_tax')" class="text-right p-0 m-0"
               style="font-size: smaller" v-html="$n(pas[json_key], 'currency', __getVueCurrencyLocale)">
            </p>
            <p v-if="isTaxpayer" :title="title + ' ' + $t('finance.with_tax')" class="text-right pt-2 m-0"
               style="font-size: smaller" v-html="$n(pas[json_key + 't'], 'currency', __getVueCurrencyLocale)">
            </p>
        </div>
        <!-- input -->
        <div v-else>
            <!-- calculated no tax price -->
            <p v-if="option === 2 && isTaxpayer"
               :title="title + ' '+ $t('finance.without_tax')"
               class="text-right pt-1 m-0 mb-2"
               style="font-size: smaller"
               v-html="$n(pas[this.json_key], 'currency', __getVueCurrencyLocale)">
            </p>
            <!-- input -->
            <div class="d-flex justify-content-around">
                <b-input-group
                    :append="!currency_prepend ? currency : null"
                    :class="option === 1 ? 'mt-1' : 'mt-0'"
                    :prepend="currency_prepend ? currency : null"
                    :title="title + ' '+ $t('finance.without_tax')"
                    size="sm"
                >
                    <b-form-input
                        v-model.number="pas[getJsonKey]"
                        class="text-right no-spinners"
                        size="sm"
                        type="number"
                        variant="primary"
                        @keypress="__onlyDecimalKey($event)"
                    ></b-form-input>
                </b-input-group>
            </div>
            <!-- calculated tax price -->
            <p v-if="option === 1 && isTaxpayer" :title="title + ' '+ $t('finance.with_tax')"
               class="text-right pt-1 m-0"
               style="font-size: smaller"
               v-html="$n(pas[this.json_key + 't'], 'currency', __getVueCurrencyLocale)">
            </p>
            <!--               v-html="$n(calc_pt, 'currency', __getVueCurrencyLocale)">-->
        </div>


        <!-- input -->
        <!--        <div v-else-if="option === 1">-->
        <!--            <div class="d-flex justify-content-around">-->
        <!--                <b-input-group-->
        <!--                    :append="!currency_prepend ? currency : null"-->
        <!--                    :prepend="currency_prepend ? currency : null"-->
        <!--                    :title="title + ' '+ $t('finance.without_tax')"-->
        <!--                    size="sm"-->
        <!--                >-->
        <!--                    <b-form-input-->
        <!--                        v-model.number="pas[json_key]"-->
        <!--                        class="text-right no-spinners"-->
        <!--                        size="sm"-->
        <!--                        variant="primary"-->
        <!--                        type="number"-->
        <!--                        @keypress="__onlyDecimalKey($event)"-->
        <!--                    ></b-form-input>-->
        <!--                </b-input-group>-->
        <!--            </div>-->
        <!--            <p v-if="isTaxpayer":title="title + ' '+ $t('finance.with_tax')" class="text-right pt-1 m-0" style="font-size: smaller"-->
        <!--               v-html="$n(calc_pt, 'currency', __getVueCurrencyLocale)"></p>-->
        <!--        </div>-->
        <!--        &lt;!&ndash; input + tax &ndash;&gt;-->
        <!--        <div v-else-if="option === 2">-->
        <!--            <p v-if="isTaxpayer" :title="title + ' '+ $t('finance.without_tax')" class="text-right pt-1 m-0" style="font-size: smaller"-->
        <!--               v-html="$n(calc_p, 'currency', __getVueCurrencyLocale)"></p>-->
        <!--            <div class="d-flex justify-content-around">-->
        <!--                <b-input-group-->
        <!--                    :append="!currency_prepend ? currency : null"-->
        <!--                    :prepend="currency_prepend ? currency : null"-->
        <!--                    :title="title + ' '+ $t('finance.with_tax')"-->
        <!--                    size="sm"-->
        <!--                >-->
        <!--                    <b-form-input-->
        <!--                        v-model.number="pas[json_key + 't']"-->
        <!--                        class="text-right no-spinners"-->
        <!--                        size="sm"-->
        <!--                        type="number"-->
        <!--                        variant="primary"-->
        <!--                        @keypress="__onlyDecimalKey($event)"-->
        <!--                    ></b-form-input>-->
        <!--                </b-input-group>-->
        <!--            </div>-->
        <!--        </div>-->
    </div>
</template>

<script>
export default {
    name: "FormProductSalePrice",
    components: {},
    props: {
        title: {
            type: String,
            default: null
        },
        currency_prepend: {
            type: Boolean,
            default: false
        },
        currency: {
            type: String,
            default: null
        },
        option: {
            type: Number,
            default: null
        },
        pas: null,
        json_key: {
            type: String,
            default: null
        },
        tax_rate: {
            type: Number,
            default: 0
        },
        isTaxpayer: {
            type: Boolean,
            default: null
        },
    },
    data() {
        return {}
    },
    mounted() {
    },
    methods: {},
    computed: {
        getJsonKey() {
            if (this.option === 2) {
                return this.json_key + 't';
            }
            return this.json_key;
        },
        calc_p() {
            let pt = this.pas[this.json_key + 't'];
            let tax_rate = this.tax_rate;
            // <--  -->
            let p = this.__calc_p(pt, tax_rate);
            this.$set(this.pas, this.json_key, p);
            return p;
        },
        calc_pt() {
            let p = this.pas[this.json_key];
            let tax_rate = this.tax_rate;
            // <--  -->
            let pt = this.__calc_pt(p, tax_rate);
            this.$set(this.pas, this.json_key + 't', pt);
            // <--  -->
            return pt;
        }
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
