<template>
    <b-modal
        :id="$options.name"
        :cancel-title="$t('form.cancel')"
        :ok-title="$t('form.save')"
        :title="title"
        header-bg-variant="primary"
        scrollable
        size="xl"
        @ok="formSave"
    >
        <!--        @show="modalOpen"-->

        <b-overlay v-if="record !== null" :opacity="overlay.opacity" :show="overlay.visible" rounded="sm">
            <b-form ref="form">
                <!-- id, active -->
                <form-id-active :record.sync="record"></form-id-active>
                <!-- left tabs -->
                <b-card no-body>
                    <b-tabs v-model="tabIndex" card pills vertical>
                        <!-- tab supplier -->
                        <b-tab :active="!record.organisation_id > 0">
                            <template #title>
                                <b-icon v-if="tabSupplierValidity === false"
                                        class="mb-1"
                                        font-scale="0.7"
                                        icon="exclamation-circle-fill"
                                        variant="danger">
                                </b-icon>
                                {{$t('trade.product.supplier')}}
                            </template>
                            <b-card-text>
                                <b-row>
                                    <b-col>
                                        <!-- organisation_id -->
                                        <b-form-group
                                            :label="$t('trade.product.supplier')"
                                            class="mb-2 mr-sm-2 mb-sm-0 pt-2"
                                            description=""
                                            label-class="mb-0"
                                            label-for="user_id"
                                        >
                                            <b-form-select
                                                id="user_id"
                                                v-model="record.organisation_id"
                                                :options="getOrganisations"
                                                :state="organisationValidity"
                                                class="mb-2 mr-sm-2 mb-sm-0"
                                                @change="supplierIdChange()"
                                            ></b-form-select>
                                            <b-form-invalid-feedback :state="organisationValidity">
                                                {{$t('form.required_field')}}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-card-text>
                        </b-tab>
                        <!-- tab basic -->
                        <b-tab :active="record.organisation_id > 0">
                            <template #title>
                                <b-icon v-if="tabBasicValidity === false"
                                        class="mb-1"
                                        font-scale="0.7"
                                        icon="exclamation-circle-fill"
                                        variant="danger">
                                </b-icon>
                                {{$t('form.tab.basic')}}
                            </template>
                            <b-card-text>
                                <b-row>
                                    <!-- column a -->
                                    <!-- meta data -->
                                    <b-col cols="6">
                                        <b-form-group
                                            :label="$tc('form.meta.data', 0)"
                                            class="mb-2"
                                            label-class="font-weight-bold pt-0"
                                            label-size="md"
                                        >
                                            <!-- name -->
                                            <b-form-group
                                                :label="$t('form.name')"
                                                class="mb-2 mr-sm-2 mb-sm-0"
                                                description=""
                                                label-class="mb-0"
                                                label-for="meta_name"
                                            >
                                                <b-form-input
                                                    id="meta_name"
                                                    v-model="record.meta.name"
                                                    :state="nameValidity"
                                                    class="mb-2 mr-sm-2 mb-sm-0"
                                                    type="text"
                                                ></b-form-input>
                                                <b-form-invalid-feedback :state="nameValidity">
                                                    {{$t('form.required_field')}}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </b-form-group>
                                        <!-- basic data -->
                                        <b-form-group
                                            class="mb-2"
                                            label=""
                                            label-class="font-weight-bold pt-0"
                                            label-size="md"
                                        >
                                            <!-- product type -->
                                            <b-form-group
                                                :label="$t('trade.product.type')"
                                                class="mb-2 mr-sm-2 mb-sm-0"
                                                description=""
                                                label-class="mb-0"
                                                label-for="product_type"
                                            >
                                                <b-form-select
                                                    id="product_type"
                                                    v-model="record.product_type_id"
                                                    :options="getProductType"
                                                    :state="productTypeIdValidity"
                                                    class="mb-2 mr-sm-2 mb-sm-0"
                                                ></b-form-select>
                                                <b-form-invalid-feedback :state="productTypeIdValidity">
                                                    {{$t('form.required_field')}}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </b-form-group>
                                    </b-col>
                                    <!-- column b -->
                                    <b-col>
                                        <b-form-group
                                            :label="$t('trade.product.production')"
                                            class="mb-2"
                                            label-class="font-weight-bold pt-0"
                                            label-size="md"
                                        >
                                            <!-- production type -->
                                            <b-form-group
                                                :label="$t('trade.product.production_type')"
                                                class="mb-2 mr-sm-2 mb-sm-0"
                                                description=""
                                                label-class="mb-0"
                                                label-for="product_type"
                                            >
                                                <b-form-select
                                                    id="product_type"
                                                    v-model="record.production_type_id"
                                                    :options="getProductionType"
                                                    class="mb-2 mr-sm-2 mb-sm-0"
                                                ></b-form-select>
                                            </b-form-group>
                                            <!-- production year -->
                                            <b-form-group
                                                :label="$t('trade.product.production_year')"
                                                class="my-2 mr-sm-2 mb-sm-0"
                                                description=""
                                                label-class="mb-0"
                                                label-for="offer_type"
                                            >
                                                <b-input
                                                    id="production_year"
                                                    v-model.number="record.content.production_year"
                                                    class="mb-2 mr-sm-2 mb-sm-0"
                                                    max="2021"
                                                    type="number"
                                                    @keypress="__onlyIntegerKey($event)"
                                                ></b-input>
                                            </b-form-group>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-card-text>
                            <!-- basic locale -->
                            <form-product-basic-locale
                                :data_locale="data.locale"
                                :record="record.locale"
                            ></form-product-basic-locale>
                        </b-tab>
                        <!-- description -->
                        <b-tab :title="$t('form.description')">
                            <b-card-text>
                                <b-form-group
                                    :label="$t('form.locale.locale')"
                                    class="mb-0 group-title p-0"
                                    label-align-sm="left"
                                    label-class="font-weight-bold"
                                    label-cols-sm="12"
                                    label-size=""
                                >
                                    <b-card no-body>
                                        <tab-locale-editor
                                            :data_locale="data.locale"
                                            :label="$t('form.description')"
                                            :record="record.locale"
                                            color_class="editor-yellow"
                                            json_key="description"
                                        ></tab-locale-editor>
                                    </b-card>
                                </b-form-group>
                            </b-card-text>
                        </b-tab>
                        <!-- ingredients -->
                        <b-tab :title="$t('trade.product.tab.ingredient')">
                            <b-card-text>
                                <b-form-group
                                    :label="$t('form.locale.locale')"
                                    class="mb-0 group-title p-0"
                                    label-align-sm="left"
                                    label-class="font-weight-bold"
                                    label-cols-sm="12"
                                    label-size=""
                                >
                                    <b-card no-body>
                                        <tab-locale-editor
                                            :data_locale="data.locale"
                                            :label="$tc('trade.product.ingredient', 2)"
                                            :record="record.locale"
                                            color_class="editor-red"
                                            json_key="ingredients"
                                        ></tab-locale-editor>
                                    </b-card>
                                </b-form-group>
                            </b-card-text>
                        </b-tab>
                        <!-- sale -->
                        <b-tab>
                            <template #title>
                                <b-icon v-if="tabSale === false"
                                        class="mb-1"
                                        font-scale="0.7"
                                        icon="exclamation-circle-fill"
                                        variant="danger">
                                </b-icon>
                                {{$t('trade.product.tab.sale')}}
                            </template>
                            <!--  -->
                            <b-form-group
                                :label="$t('trade.product.tab.sale')"
                                class="mb-0"
                                label-class="font-weight-bold pt-0"
                                label-size="md"
                            >
                                <!-- taxpayer === null -->
                                <div v-if="isTaxpayerNull.isNull">
                                    <div>
                                        {{isTaxpayerNull.messageTenant}}
                                    </div>
                                    <div>
                                        {{isTaxpayerNull.messageSupplier}}
                                    </div>
                                </div>
                                <!-- sale -->
                                <b-card-text v-else>
                                    <b-form-row>
                                        <b-col>
                                            <!-- offer type -->
                                            <b-form-group
                                                :label="$tc('register.offer_type', 0)"
                                                class="mb-2 mr-sm-2 mb-sm-0"
                                                description=""
                                                label-class="mb-0"
                                                label-for="offer_type"
                                            >
                                                <b-form-select
                                                    id="product_type"
                                                    v-model="record.offer_type_id"
                                                    :options="getOfferTypes"
                                                    :state="offerTypeIdValidity"
                                                    class="mb-2 mr-sm-2 mb-sm-0"
                                                ></b-form-select>
                                                <b-form-invalid-feedback :state="offerTypeIdValidity">
                                                    {{$t('form.required_field')}}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </b-col>
                                        <b-col>
                                            <!-- tax_rate -->
                                            <b-form-group
                                                v-if="isSupplierTaxpayer || isTenantTaxpayer"
                                                :label="$tc('register.tax_rate', 1)"
                                                class="mb-2 mr-sm-2 mb-sm-0"
                                                description=""
                                                label-class="mb-0"
                                                label-for="tax_rate"
                                            >
                                                <b-form-select
                                                    id="tax_rate"
                                                    v-model="record.tax_rate_id"
                                                    :options="getTaxRates"
                                                    :state="taxRateIdValidity"
                                                    class="mb-2 mr-sm-2 mb-sm-0"
                                                ></b-form-select>
                                                <b-form-invalid-feedback :state="taxRateIdValidity">
                                                    {{$t('form.required_field')}}
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </b-col>
                                    </b-form-row>
                                    <b-form-group
                                        class="mt-2"
                                        label=""
                                        label-class="font-weight-bold p-0 "
                                        label-size="md"
                                    >
                                        <form-product-pas
                                            :basic_units="data.basic_unit"
                                            :is-supplier-taxpayer="isSupplierTaxpayer"
                                            :is-tenant-taxpayer="isTenantTaxpayer"
                                            :organisation_id="record.organisation_id"
                                            :packings="data.packing"
                                            :record="record.sale"
                                            :tabValidity="tabValidity"
                                            :tax_rate="getTaxRate"
                                            class="mt-2"
                                        >
                                        </form-product-pas>
                                    </b-form-group>
                                    <!-- alert warning-->
                                    <b-alert
                                        :show="alertVisible && isTenantTaxpayer"
                                        class="mt-2 mr-2"
                                        dismissible
                                        variant="warning"
                                    >
                                        <div v-html="getErrorsMessage"></div>
                                    </b-alert>
                                </b-card-text>
                            </b-form-group>
                        </b-tab>
                        <!-- pictures -->
                        <b-tab :title="$t('form.tab.picture')">
                            <!--                            <b-card-text>-->
                            <!--                                <b-form-group-->
                            <!--                                    :label="$t('form.tab.picture')"-->
                            <!--                                    class="mb-0"-->
                            <!--                                    label-class="font-weight-bold pt-0"-->
                            <!--                                    label-size="md"-->
                            <!--                                >-->
                            <PictureManagerDeprecated
                                :picture-manager-bus="pictureManagerBus"
                                @eventUpdate="eventPictureManagerUpdate"
                            >
                            </PictureManagerDeprecated>
                            <!--                                </b-form-group>-->
                            <!--                            </b-card-text>-->
                        </b-tab>
                        <!-- requirements -->
                        <b-tab :title="$t('trade.product.tab.requirement')">
                            <b-card-text>
                                <!-- label -->
                                <b-row>
                                    <label class="ml-3 font-weight-bold">
                                        {{$t('trade.product.tab.requirement')}}
                                    </label>
                                </b-row>
                                <!-- temperature_control -->
                                <b-row>
                                    <b-col>
                                        <b-form-group
                                            :label="$t('trade.product.temperature_control')"
                                            class="mb-0"
                                            label-class="font-weight-bold pt-0"
                                            label-size="md"
                                        >
                                            <!-- handling -->
                                            <b-form-group
                                                :label="$t('trade.product.handling')"
                                                class="mb-2 mr-sm-2 mb-sm-0"
                                                description=""
                                                label-class="mb-0"
                                                label-for="handling"
                                            >
                                                <b-form-select
                                                    id="handling"
                                                    v-model="record.requirement_id"
                                                    :options="getRequirements"
                                                    class="mb-2 mr-sm-2 mb-sm-0"
                                                ></b-form-select>
                                            </b-form-group>
                                        </b-form-group>
                                    </b-col>
                                    <!-- alcohol -->
                                    <b-col>
                                        <b-form-group
                                            :label="$t('trade.product.alcohol')"
                                            class="mb-0"
                                            label-class="font-weight-bold pt-0"
                                            label-size="md"
                                        >
                                            <!-- alcohol_containing -->
                                            <b-form-group
                                                :label="$t('trade.product.alcohol_containing')"
                                                class="mb-2 mr-sm-2 mb-sm-0"
                                                description=""
                                                label-class="mb-0"
                                                label-for="alcohol_containing"
                                            >
                                                <b-input-group
                                                    id="alcohol_containing"
                                                    :append="$tc('trade.product.alcohol_containing_unit', 1)">
                                                    <b-form-input
                                                        v-model.number="record.content.alcohol_containing"
                                                        class="text-right no-spinners"
                                                        type="number"
                                                        variant="primary"
                                                        @keypress="__onlyDecimalKey($event)"
                                                    ></b-form-input>
                                                </b-input-group>
                                            </b-form-group>
                                            <!-- legal_alcohol_age -->
                                            <b-form-checkbox
                                                id="legal_alcohol_age"
                                                v-model="record.content.legal_alcohol_age"
                                                class="pt-2"
                                            >
                                                {{$tc('trade.product.minimal_legal_age', 1)}}
                                            </b-form-checkbox>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-card-text>
                        </b-tab>
                        <!-- certificates -->
                        <b-tab :title="$t('organisation.tab.certificate')">
                            <b-card-text>
                                <b-form-group
                                    :label="$t('organisation.tab.certificate')"
                                    class="mb-0"
                                    label-class="font-weight-bold pt-0"
                                    label-size="md"
                                >
                                    <form-table-certificate-select
                                        :certificates="data.certificates"
                                        :organisation_id="record.organisation_id"
                                        :record.sync="record.certificate"
                                    ></form-table-certificate-select>
                                </b-form-group>
                            </b-card-text>
                        </b-tab>
                        <!-- notes -->
                        <b-tab :title="$t('form.tab.notes')">
                            <b-card-text>
                                <b-form-group
                                    :label="$t('form.locale.locale')"
                                    class="mb-0 group-title p-0"
                                    label-align-sm="left"
                                    label-class="font-weight-bold"
                                    label-cols-sm="12"
                                    label-size=""
                                >
                                    <b-card no-body>
                                        <tab-locale-editor
                                            :data_locale="data.locale"
                                            :label="$tc('form.notes', 2)"
                                            :record="record.locale"
                                            json_key="notes"
                                        ></tab-locale-editor>
                                    </b-card>
                                </b-form-group>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-card>
                <!--  -->
            </b-form>
        </b-overlay>
    </b-modal>
</template>

<script>
import Vue from "vue";
import FormIdActive from "@/components/general/elements/formElements/FormIdActive";
import TabLocaleEditor from "@/components/general/elements/tabAccessories/TabLocaleEditor";
import FormTableCertificateSelect
    from "@/components/general/elements/formElements/certificate/FormTableCertificateSelect";
import FormProductPas from "@/components/trade/product/FormProductPas";
import FormProductBasicLocale from "@/components/trade/product/FormProductBasicLocale";
import PictureManagerDeprecated
    from "@/components/general/elements/pictures/pictures_manager_deprecated/PictureManagerDeprecated";

export default {
    name: "ModalFormProduct",
    components: {
        PictureManagerDeprecated,
        FormProductBasicLocale,
        FormProductPas,
        FormTableCertificateSelect,
        TabLocaleEditor,
        FormIdActive
    },
    props: {
        localeKey: null,
        uri: null,
        form: {},
        isTenantTaxpayer: {
            type: Boolean,
            default: null
        },
        modalBus: Object,
    },
    data() {
        return {
            overlay: {
                visible: false,
                opacity: 0.7,
            },
            tabIndex: 0,
            data: {},
            division: 'product',
            // <-- record -->
            recordDefault: {
                id: 0,
                organisation_id: null,
                product_type_id: null,
                production_type_id: null,
                requirement_id: null,
                offer_type_id: null,
                tax_rate_id: null,
                active: false,
                meta: {
                    name: null,
                },
                content: {},
                locale: {},
                certificate: {},
                sale: {
                    pas: [],
                    price: {
                        wsp_option: 1,
                        c_option: 1,
                        rp_option: null,
                    },
                },
                pictures_order: [],
                pictures: [],
            },
            record: null,
            // recordClone: {},
            // <-- validity -->
            validityDefault: {
                check: false,
            },
            tabValidity: {},
            // <-- errors -->
            errorsDefault: {
                message: null,
            },
            errors: {
                message: null
            },
            pictureManagerBus: new Vue(),
        }
    },
    mounted() {
        this.modalBus.$on('modalOpen', (recordId) => {
            // this.recordId = recordId;
            this.form.id = recordId;
            this.modalOpen();
        });
    },
    methods: {
        showOverlay(show) {
            this.overlay.visible = show;
        },
        resetValidity() {
            this.$set(this, 'tabValidity', this.__clone(this.validityDefault));
        },
        resetErrors() {
            this.$set(this, 'errors', this.__clone(this.errorsDefault));
        },
        // contactUpdate(data) {
        //     this.$set(this.record.contact, data.key, data.values);
        // },
        // certificateUpdate(data) {
        //     this.$set(this.record.certificate, data.key, data.values);
        // },
        // <-- table -->
        tableRowDetail(locale) {
            // let detail = null;
            // Object.keys(locale).find((n) => {
            //     if (n === this.$i18n.locale) {
            //         detail = locale[n].notes;
            //     }
            // });
            // return detail;
        },
        // <-- supplier -->
        supplierIdChange() {
            if (this.record.organisation_id === null) {
                this.$set(this.data, 'certificates', null);
                return;
            }
            // <--  -->
            this.showOverlay(true);
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <-- axios -->
            this.axios.get(this.uri.uri1 + `/getOrganisationCertificate/${this.record.organisation_id}`, config)
                .then((response) => {
                    this.$set(this.data, 'certificates', response.data);
                    // <--  -->
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // on error executed
                });
        },
        // <-- modal -->
        modalOpen() {
            this.resetValidity();
            this.resetErrors();
            // <--  -->
            if (this.form.id > 0) {
                this.loadEdit();
                return;
            }
            this.loadNew();
        },
        modalClose() {
            this.showOverlay(false);
        },
        hideModal(data) {
            this.$emit('refresh', data);
            // <-- hide the modal manually -->
            this.$nextTick(() => {
                this.$bvModal.hide(this.$options.name);
            });
        },
        // <-- form -->
        formSave(bvModalEvent) {
            bvModalEvent.preventDefault();
            // <--  -->
            this.tabValidity.check = true;
            let validity = true;
            // <-- check -->
            this.$nextTick(() => {
                Object.keys(this.tabValidity).forEach(key => {
                    if (key !== 'check' && !this.tabValidity[key]) {
                        validity = false;
                    }
                });
                // <-- save -->
                if (validity) {
                    if (this.record.id > 0) {
                        this.baseUpdateFormData();
                        return;
                    }
                    this.baseInsertFormData();
                }
            });
        },
        // <-- api -->
        loadNew() {
            this.showOverlay(true);
            // <-- record -->
            // this.$nextTick(() => {
            this.$set(this, 'record', this.__clone(this.recordDefault));
            // this.$set(this, 'recordClone', this.__clone(this.record));
            // });
            // <-- api -->
            let organisation_id = 0;
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <-- axios -->
            this.axios.get(this.uri.uri1 + `/getForeignDataActive/${organisation_id}`, config)
                .then((response) => {
                    this.$set(this, 'data', response.data);
                    this.$bvModal.show('ModalFormProduct');
                    // <-- picture -->
                    let recordPictures = {
                        pictures_order: this.record.pictures_order,
                        pictures: this.record.pictures
                    };
                    this.$nextTick(function () {
                        this.pictureManagerBus.$emit('recordLoad', recordPictures);
                    });
                    // <--  -->
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // on error executed
                });
        },
        loadEdit() {
            this.showOverlay(true);
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <-- axios -->
            this.axios.get(this.uri.main + `/${this.form.id}`, config)
                .then((response) => {
                    // <-- data -->
                    this.$set(this, 'data', response.data.data);
                    // <-- record -->
                    let recordDefault = this.__clone(this.recordDefault);
                    recordDefault.sale.price = null;    // exclude to update

                    let record = response.data.record;
                    this.__mergeObjectDeep(recordDefault, record);
                    this.$set(this, 'record', record);

                    this.$bvModal.show('ModalFormProduct');
                    // <-- pictures -->
                    let recordPictures = {
                        pictures_order: this.record.pictures_order,
                        pictures: this.record.pictures
                    };
                    this.$nextTick(function () {
                        this.pictureManagerBus.$emit('recordLoad', recordPictures)
                    });
                    // <--  -->
                    this.showOverlay(false);
                })
                .catch((error) => {
                    console.log("error:", error.data);
                });
        },
        // <-- events pictureManager -->
        eventPictureManagerUpdate(data) {
            this.$set(this.record, 'pictures_order', data.pictures_order);
            this.$set(this.record, 'pictures', data.pictures);
        },
        // <-- base -->
        baseInsertFormData() {
            this.showOverlay(true);
            // <-- clear errors -->
            // this.resetErrors();
            // <-- formData -->
            const config = {
                headers: this.__getFormDataHeaders(false)
            }
            let formData = new FormData();
            // <-- prepare files for axios -->
            if (this.record.pictures !== undefined) {
                this.record.pictures.forEach((picture, index) => {
                    formData.append('division', this.division);
                    formData.append('files[' + index + ']', picture.file);
                    formData.append('keys[' + index + ']', picture.key);
                });
            }
            // <-- prepare data for axios -->
            formData.append('locale', JSON.stringify(this.$i18n.locale));
            formData.append('record', JSON.stringify(this.record));
            // <-- api -->
            this.axios.post(this.uri.uri1 + '/form_data_insert', formData, config)
                .then((response) => {
                    this.hideModal(response.data);
                    this.showOverlay(false);
                })
                .catch((error) => {
                    error = error.response.data;
                    this.errors.message = error;
                    this.showOverlay(false);
                });
        },
        baseUpdateFormData() {
            this.showOverlay(true);
            // <--  -->
            let record = this.record;
            // <-- clear errors -->
            this.resetErrors();
            // <-- set formData -->
            const config = {
                headers: this.__getFormDataHeaders(false)
            }
            let formData = new FormData();
            let imagesUpdate = [];
            // <-- prepare files for axios -->
            if (this.record.pictures !== undefined) {
                this.record.pictures.forEach((picture, index) => {
                    if (picture.id === 0) {
                        formData.append('division', this.division);
                        formData.append('files[' + index + ']', picture.file);
                        formData.append('keys[' + index + ']', picture.key);
                    } else {
                        imagesUpdate.push(picture);
                    }
                });
            }
            formData.append('imagesUpdate', JSON.stringify(imagesUpdate));
            // <-- prepare data for axios -->
            formData.append('locale', JSON.stringify(this.$i18n.locale));
            formData.append('record', JSON.stringify(this.record));
            // <-- api -->
            this.axios.post(this.uri.uri1 + '/form_data_update' + `/${this.record.id}`, formData, config)
                .then((response) => {
                    this.hideModal(response.data);
                    this.showOverlay(false);
                })
                .catch((error) => {
                    error = error.response.data;
                    this.errors.message = error;
                    this.showOverlay(false);
                });
        },
    },
    computed: {
        title: {
            get() {
                let record = this.record;
                if (this.form.id <= 0 || record === null || record.meta === undefined || record.meta.name === undefined) {
                    return this.$t('form.add') + ': ' + this.$tc(this.localeKey, 0).toLowerCase();
                }
                // <-- name -->
                let name = record.meta.name;
                // <--  -->
                return this.$tc(this.localeKey, 0) + ': ' + name;
            },
        },
        // <-- data -->
        getOrganisations() {
            let array = [
                {
                    text: this.$t('form.choose_placeholder'),
                    value: null
                }
            ];
            // <-- check data -->
            if (this.__isNull(this.data) || this.__isNull(this.data.organisations)) {
                return array;
            }
            // <--  -->
            let items = this.data.organisations;
            items.forEach(item => {
                array.push({
                    text: (this.__isNull(item.name) || this.__isNull(item.name.name) ? item.name.full_name : item.name.name),
                    value: item.id,
                    taxpayer: item.taxpayer
                });
            });
            // <--  -->
            return array;
        },
        getProductType() {
            let array = [];
            // <-- check data -->
            if (this.__isNull(this.data) || this.__isNull(this.data.product_types)) {
                array.push(
                    {
                        text: this.$t('form.choose_placeholder'),
                        value: null
                    });
                return array;
            }
            // <--  -->
            let items = this.data.product_types;
            let text = null;
            items.forEach(item => {
                text = item.meta.name;
                // <-- locale -->
                if (item.locale[this.$i18n.locale] !== undefined) {
                    if (!this.__isNull(item.locale[this.$i18n.locale].name)) {
                        text = item.locale[this.$i18n.locale].name;
                    }
                }
                // <-- push -->
                array.push({
                    text: text,
                    value: item.id
                });
            });
            // <-- order by text -->
            array = array.sort((a, b) => {
                let fa = a.text.toLowerCase(),
                    fb = b.text.toLowerCase();

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });
            // <-- add placeholder on top -->
            array.unshift(
                {
                    text: this.$t('form.choose_placeholder'),
                    value: null
                });
            // <--  -->
            return array;
        },
        getProductionType() {
            let array = [];
            // <-- check data -->
            if (this.__isNull(this.data) || this.__isNull(this.data.production_types)) {
                array.push(
                    {
                        text: this.$t('form.choose_placeholder'),
                        value: null
                    });
                return array;
            }
            // <--  -->
            let items = this.data.production_types;
            let text = null;
            items.forEach(item => {
                text = item.meta.name;
                // <-- locale -->
                if (item.locale[this.$i18n.locale] !== undefined) {
                    if (!this.__isNull(item.locale[this.$i18n.locale].name)) {
                        text = item.locale[this.$i18n.locale].name;
                    }
                }
                // <-- push -->
                array.push({
                    text: text,
                    value: item.id
                });
            });
            // <-- order by text -->
            array = array.sort((a, b) => {
                let fa = a.text.toLowerCase(),
                    fb = b.text.toLowerCase();

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });
            // <-- add placeholder on top -->
            array.unshift(
                {
                    text: this.$t('form.choose_placeholder'),
                    value: null
                });
            // <--  -->
            return array;
        },
        getOfferType() {
            let array = [];
            // <-- check data -->
            if (this.__isNull(this.data) || this.__isNull(this.data.offer_types)) {
                array.push(
                    {
                        text: this.$t('form.choose_placeholder'),
                        value: null
                    });
                return array;
            }
            // <--  -->
            let items = this.data.offer_types;
            let text = null;
            items.forEach(item => {
                text = item.meta.name;
                // <-- locale -->
                if (item.locale[this.$i18n.locale] !== undefined) {
                    if (!this.__isNull(item.locale[this.$i18n.locale].name)) {
                        text = item.locale[this.$i18n.locale].name;
                    }
                }
                // <-- push -->
                array.push({
                    text: text,
                    value: item.id
                });
            });
            // <-- order by text -->
            array = array.sort((a, b) => {
                let fa = a.text.toLowerCase(),
                    fb = b.text.toLowerCase();

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });
            // <-- add placeholder on top -->
            array.unshift(
                {
                    text: this.$t('form.choose_placeholder'),
                    value: null
                });
            // <--  -->
            return array;
        },
        getOfferTypes() {
            let array = [];
            // <-- check data -->
            if (this.__isNull(this.data.offer_types)) {
                array.push(
                    {
                        text: this.$t('form.choose_placeholder'),
                        value: null
                    });
                return array;
            }
            // <--  -->
            let items = this.data.offer_types;
            let text = null;
            items.forEach(item => {
                text = item.meta.name;
                // <-- locale -->
                if (item.locale[this.$i18n.locale] !== undefined) {
                    if (!this.__isNull(item.locale[this.$i18n.locale].name)) {
                        text = item.locale[this.$i18n.locale].name;
                    }
                }
                // <-- push -->
                array.push({
                    text: text,
                    value: item.id
                });
            });
            // <-- order by text -->
            array = array.sort((a, b) => {
                let fa = a.text.toLowerCase(),
                    fb = b.text.toLowerCase();

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });
            // <-- add placeholder on top -->
            array.unshift(
                {
                    text: this.$t('form.choose_placeholder'),
                    value: null
                });
            // <--  -->
            return array;
        },
        getTaxRate() {
            if (this.record.tax_rate_id === null) {
                return 0;
            }
            // <--  -->
            let tax_rate = 0;
            // <--  -->
            this.getTaxRates.find((n) => {
                if (n.value === this.record.tax_rate_id) {
                    tax_rate = n.rate;
                }
            });
            // <--  -->;
            return tax_rate;
        },
        getTaxRates() {
            let array = [];
            // <-- check data -->
            if (this.__isNull(this.data.tax_rates)) {
                array.push(
                    {
                        text: this.$t('form.choose_placeholder'),
                        value: null,
                        rate: 0
                    });
                return array;
            }
            // <--  -->
            let items = this.data.tax_rates;
            let text = null;
            items.forEach(item => {
                text = item.meta.name;
                // <-- locale -->
                if (item.locale[this.$i18n.locale] !== undefined) {
                    if (!this.__isNull(item.locale[this.$i18n.locale].name)) {
                        text = item.locale[this.$i18n.locale].name;
                    }
                }
                // <-- push -->
                array.push({
                    text: text,
                    value: item.id,
                    rate: item.content.tax_rate,
                });
            });
            // <-- add placeholder on top -->
            array.unshift(
                {
                    text: this.$t('form.choose_placeholder'),
                    value: null
                });
            // <--  -->
            return array;
        },
        getRequirements() {
            let array = [];
            // <-- check data -->
            if (this.__isNull(this.data) || this.__isNull(this.data.requirements)) {
                array.push(
                    {
                        text: this.$t('form.choose_placeholder'),
                        value: null
                    });
                return array;
            }
            // <--  -->
            let items = this.data.requirements;
            let id;
            let text = null;
            items.forEach(item => {
                id = item.id;
                text = item.meta.name;

                // <-- locale -->
                if (item.locale[this.$i18n.locale] !== undefined) {
                    if (!this.__isNull(item.locale[this.$i18n.locale].name)) {
                        text = item.locale[this.$i18n.locale].name;
                    }
                }
                // <-- push -->
                array.push({
                    text: text,
                    value: id
                });
            });
            // <-- order by text -->
            /*            array = array.sort((a, b) => {
                            let fa = a.text.toLowerCase(),
                                fb = b.text.toLowerCase();

                            if (fa < fb) {
                                return -1;
                            }
                            if (fa > fb) {
                                return 1;
                            }
                            return 0;
                        });*/
            // <-- add placeholder on top -->
            array.unshift(
                {
                    text: this.$t('form.choose_placeholder'),
                    value: null
                });
            // <--  -->
            return array;
        },
        isTaxpayerNull() {
            if (this.isTenantTaxpayer === null || this.isSupplierTaxpayer === null) {
                let messageTenant = this.isTenantTaxpayer === null ? this.$t('tenant.tenant_not_selected') : null;
                let messageSupplier = this.isSupplierTaxpayer === null ? this.$t('trade.product.supplier_not_selected') : null;
                return {
                    isNull: true,
                    messageTenant: messageTenant,
                    messageSupplier: messageSupplier,
                };
            }
            // <--  -->
            return {
                isNull: false,
                messageTenant: null,
                messageSupplier: null,
            };
        },
        isSupplierTaxpayer() {
            if (this.record === null || !this.record.organisation_id > 0) {
                return null;
            }
            // <--  -->
            let taxpayer = null;
            let org_id = this.record.organisation_id;
            this.getOrganisations.find((org) => {
                if (org.value === org_id) {
                    taxpayer = org.taxpayer
                }
            });
            // <--  -->
            return taxpayer;
        },
        // <-- alerts -->
        alertVisible() {
            if (this.record === null) {
                return false;
            }
            // <--  -->
            return this.record.tax_rate_id === null;
        },
        getErrorsMessage() {
            let message = '';
            if (this.record !== null && this.record.tax_rate_id === null) {
                message += this.$t('finance.tax_rate_not_selected');
            }
            // <--  -->
            return message;
        },
        // <-- validity: tab supplier -->
        organisationValidity() {
            if (this.tabValidity === undefined || this.tabValidity.check === false) {
                return null;
            }
            // <--  -->
            return this.record.organisation_id > 0 ? null : false;
        },
        tabSupplierValidity() {
            let tabValidity = this.organisationValidity;
            // <--  -->
            this.$set(this.tabValidity, 'tabSupplier', tabValidity !== false);
            return tabValidity;
        },
        // <-- validity: tab basic -->
        nameValidity() {
            if (this.tabValidity === undefined || this.tabValidity.check === false) {
                return null;
            }
            // <--  -->
            let validity = !this.__isNull(this.record.meta.name);
            return validity ? null : false;
        },
        productTypeIdValidity() {
            if (this.tabValidity === undefined || this.tabValidity.check === false) {
                return null;
            }
            // <--  -->
            let validity = this.record.product_type_id > 0;
            return validity ? null : validity;
        },
        tabBasicValidity() {
            let tabValidity = true;
            tabValidity = tabValidity === false || this.nameValidity === false ? false : tabValidity;
            tabValidity = tabValidity === false || this.productTypeIdValidity === false ? false : tabValidity;
            // <--  -->
            this.$set(this.tabValidity, 'tabBasic', tabValidity);
            return tabValidity
        },
        // <-- validity: sale -->
        offerTypeIdValidity() {
            if (this.tabValidity === undefined || this.tabValidity.check === false) {
                return null;
            }
            // <--  -->
            let validity = this.record.offer_type_id > 0;
            return validity ? null : validity;
        },
        taxRateIdValidity() {
            if (this.tabValidity === undefined || this.tabValidity.check === false) {
                return null;
            }
            // <--  -->
            if (!this.isSupplierTaxpayer && this.isTenantTaxpayer !== true) {
                return true;
            }
            // <--  -->
            let validity = this.record.tax_rate_id > 0;
            return validity ? null : validity;
        },
        tabSale() {
            let tabValidity = true;
            // <--  -->
            tabValidity = tabValidity === false || this.offerTypeIdValidity === false ? false : tabValidity;
            tabValidity = tabValidity === false || this.taxRateIdValidity === false ? false : tabValidity;
            tabValidity = tabValidity === false || this.tabValidity.pas === false ? false : tabValidity;
            // // <--  -->
            this.$set(this.tabValidity, 'tabSale', tabValidity);
            // <--  -->
            return tabValidity;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
