<template>
    <div>
        <!-- table pas (package and sale) -->
        <b-form-row class="m-0">
            <b-form-group
                class="mb-2 mr-sm-2 mb-sm-0"
                description=""
                label-class="mb-0"
            >
                <b-table
                    :fields="tableFields"
                    :items="record.pas"
                    bordered
                    class="m-0"
                    head-row-variant=""
                    hover
                    striped
                    table-class="text-center"
                    table-variant=""
                >
                    <!-- head top -->
                    <template #thead-top="data">
                        <b-tr>
                            <b-th colspan="3" variant="secondary">{{$tc('trade.product.packaging', 1)}}</b-th>
                            <b-th colspan="3" variant="danger">{{$t('trade.product.price')}}</b-th>
                            <b-th colspan="1" variant="">
                                <b-col>
                                    <!-- calculate price table -->
                                    <b-icon-calculator-fill
                                        v-b-tooltip.hover
                                        :title="$t('form.delete')"
                                        scale="1.3"
                                        style="cursor: pointer;"
                                        variant=""
                                        @click="modalPriceOptionOpen(null)"
                                    ></b-icon-calculator-fill>
                                </b-col>
                            </b-th>
                        </b-tr>
                    </template>
                    <!-- actions head -->
                    <template #head(action)="row">
                        <b-icon-plus-square
                            v-b-tooltip.hover
                            :title="$t('trade.product.hover_add_packaging')"
                            scale="1.2"
                            style="cursor: pointer;"
                            @click="itemAdd()"
                        ></b-icon-plus-square>
                        <!--                        :title="$t('trade.product.hover_add_packaging')"  ASKIT Mojca Preveri z Mojco!!!-->
                    </template>
                    <!-- actions -->
                    <template v-slot:cell(action)="row">
                        <div class="d-flex justify-content-around">
                            <!-- calculate price row -->
                            <!--                        <b-icon-calculator-fill
                                                        v-b-tooltip.hover
                                                        :title="$t('form.delete')"
                                                        scale="1.3"
                                                        style="cursor: pointer;"
                                                        variant="primary"
                                                        @click="modalPriceOptionOpen(row.item)"
                                                    ></b-icon-calculator-fill>-->
                            <!-- delete -->
                            <b-icon-trash
                                v-b-tooltip.hover
                                :style="isTableRowDeleteEnable === false ? 'color: gray': ''"
                                :title="$t('form.delete')"
                                :variant="isTableRowDeleteEnable === false ? '' : 'danger'"
                                scale="1.3"
                                style="cursor: pointer;"
                                @click="itemDelete(row.item)"
                            ></b-icon-trash>
                        </div>
                        <!--                            :title="$t('trade.product.hover_delete_packaging')"-->
                        <!--ASKIT Mojca - preveri pri Mojci-->
                    </template>
                    <!-- quantity -->
                    <template v-slot:cell(quantity)="row">
                        <div class="d-flex justify-content-around">
                            <b-form-input
                                v-model.number="row.item.quantity"
                                :title="$t('trade.product.quantity_tooltip')"
                                class="text-right no-spinners"
                                size="sm"
                                type="number"
                                variant="primary"
                                @keypress="__onlyDecimalKey($event)"
                            ></b-form-input>
                        </div>
                    </template>
                    <!-- basic unit -->
                    <template v-slot:cell(unit)="row">
                        <div class="d-flex justify-content-around">
                            <b-form-select
                                v-model="row.item.unit_id"
                                :options="getBasicUnits"
                                :title="$t('trade.product.unit_tooltip')"
                                class=""
                                size="sm"
                                variant="primary"
                            ></b-form-select>
                        </div>
                        <p
                            :title="$tc('trade.product.retail_price', 1) + ' ' + $t('finance.with_tax') + ' ' + $t('finance.per_unit')"
                            class="text-right p-1 m-0" style="font-size: smaller"
                            v-html="$n(row.item['ppi'], 'currency', __getVueCurrencyLocale) + '/' + getUnit(row.item.unit_id)"
                        >
                        </p>
                    </template>
                    <!-- packagings -->
                    <template v-slot:cell(packaging)="row">
                        <div class="d-flex justify-content-around">
                            <b-form-select
                                v-model="row.item.packaging_id"
                                :options="getPackings"
                                :title="$t('trade.product.packaging_tooltip')"
                                class=""
                                size="sm"
                                variant="primary"
                            ></b-form-select>
                        </div>
                    </template>
                    <!-- whole sale price -->
                    <template v-slot:cell(wholeSalePrice)="row">
                        <form-product-sale-price
                            :currency="getCurrency"
                            :currency_prepend=false
                            :is-taxpayer="isSupplierTaxpayer"
                            :option="record.price.wsp_option"
                            :pas="row.item"
                            :tax_rate="tax_rate"
                            :title="$tc('trade.product.wholesale_price', 1)"
                            json_key="wsp"
                        ></form-product-sale-price>
                    </template>
                    <!-- commission -->
                    <template v-slot:cell(commission)="row">
                        <form-product-sale-commission
                            :currency="getCurrency"
                            :currency_prepend=false
                            :option="record.price.c_option"
                            :pas="row.item"
                            :title="$tc('finance.commission', 1)"
                            json_key="c"
                        ></form-product-sale-commission>
                    </template>
                    <!-- retail price -->
                    <template v-slot:cell(retailPrice)="row">
                        <form-product-sale-price
                            :currency="getCurrency"
                            :currency_prepend=false
                            :is-taxpayer="isTenantTaxpayer"
                            :option="record.price.rp_option"
                            :pas="row.item"
                            :tax_rate="tax_rate"
                            :title="$tc('trade.product.retail_price', 1)"
                            json_key="rp"
                        ></form-product-sale-price>
                    </template>
                </b-table>
                <b-form-invalid-feedback :state="pasValidity">
                    {{$t('form.table_required_fields')}}
                </b-form-invalid-feedback>
            </b-form-group>
        </b-form-row>
        <!-- modal price option-->
        <modal-price-options
            :form-price="record.price"
            :is-supplier-taxpayer="isSupplierTaxpayer"
            :is-tenant-taxpayer="isTenantTaxpayer"
            :row-key="price.rowKey"
        ></modal-price-options>
    </div>
</template>

<script>
import ModalPriceOptions from "@/components/trade/product/ModalPriceOptions";
import FormProductSalePrice from "@/components/trade/product/FormProductSalePrice";
import FormProductSaleCommission from "@/components/trade/product/FormProductSaleCommission";

export default {
    name: "FormProductPas",
    components: {FormProductSaleCommission, FormProductSalePrice, ModalPriceOptions},
    props: {
        organisation_id: {
            type: Number,
            default() {
                return 0
            }
        },
        emptyRowsNumber: {
            type: Number,
            default() {
                return 1
            }
        },
        record: {
            type: Object,
            default() {
                return null
            }
        },
        basic_units: {
            type: Array,
            default() {
                return []
            }
        },
        packings: {
            type: Array,
            default() {
                return []
            }
        },
        tabValidity: {
            type: Object,
            default() {
                return {}
            }
        },
        tax_rate: {
            type: Number,
            default: 0
        },
        isTenantTaxpayer: {
            type: Boolean,
            default: null
        },
        isSupplierTaxpayer: {
            type: Boolean,
            default: null
        },
    },
    data() {
        return {
            table: {
                fields: [
                    {
                        key: 'quantity',
                        label: this.$t('trade.product.quantity'),
                        // sortable: true,
                        show: true,
                        thStyle: {width: '85px'}
                    },
                    {
                        key: 'unit',
                        label: this.$tc('trade.product.unit', 0),
                        // sortable: true,
                        show: true,
                        thStyle: {width: '15%'},
                    },
                    {
                        key: 'packaging',
                        label: this.$t('trade.product.packaging_material'),
                        // sortable: true,
                        show: true,
                        thStyle: {width: '18%'},
                    },
                    {
                        key: 'wholeSalePrice',
                        label: this.$tc('trade.product.wholesale_price', 2),
                        // sortable: true,
                        show: true,
                        thStyle: {width: '15%'},
                    },
                    {
                        key: 'commission',
                        label: this.$tc('finance.commission', 2),
                        // sortable: true,
                        show: true,
                        tdClass: 'align-middle',
                        thStyle: {width: '85px'}
                    },
                    {
                        key: 'retailPrice',
                        label: this.$tc('trade.product.retail_price', 2),
                        // sortable: true,
                        show: true,
                        thStyle: {width: '15%'},
                    },
                    {
                        key: 'action',
                        label: '',
                        tdClass: 'align-middle',
                        thStyle: {width: '60px'},
                    },
                ],
            },
            price: {
                rowKey: null,
            },
            validity: {},
        }
    },
    mounted() {
        this.$set(this.tabValidity, 'pas', true);
        this.setItems();
    },
    methods: {
        changeSupplier() {
            this.$set(this.record, 'pas', []);
            this.itemAdd();
        },
        // <-- table items -->
        defaultItemAdd() {
            if (this.record.pas.length > 0) {
                return;
            }
            // <--  -->
            for (let i = 0; i < this.emptyRowsNumber; i++) {
                this.itemAdd();
            }
        },
        itemAdd() {
            let index = this.record.pas.length;
            this.record.pas.push(
                {
                    key: this.__createUniqueKey(index),
                    quantity: null,
                    unit_id: null,
                    ppi: null,
                    packaging_id: null,
                    wsp: null,      // <-- wholeSalePrice -->
                    wspt: null,     // <-- wholeSalePrice + tax -->
                    cp: 20,         // <-- commission -->
                    cc: null,       // <-- commission -->
                    rp: null,       // <-- retail price -->
                    rpt: null       // <-- retail price +tax -->
                }
            );
        },
        itemDelete(row) {
            if (!this.isTableRowDeleteEnable) {
                return;
            }
            // <--  -->
            let items = this.record.pas;
            let key = row.key;
            // <-- find row index -->
            let index = -1;

            items.find((n, indexTemp) => {
                if (n.key === key) {
                    index = indexTemp;
                }
            });
            // <-- remove item -->
            this.record.pas.splice(index, 1);
        },
        setItems() {
            let record = this.record.pas;
            // <-- default items -->
            if (record.length === 0) {
                this.record.pas = [];
                this.defaultItemAdd();
                return;
            }
            // <-- set items -->
            let items = [];
            // <-- set items from record -->
            record.forEach((row, index) => {
                items.push(
                    {
                        key: this.__createUniqueKey(index),
                        quantity: 25.3.toLocaleString('sl-SI'),
                        unit: null,
                        packaging: null,
                        wholeSalePrice: '100,00',
                        commission: '20 %',
                        retailPrice: '200,00'
                    }
                );
            });
            // <--  -->
            this.$set(this.table, 'items', items);
        },
        // <-- price -->
        modalPriceOptionOpen(item) {
            this.price.rowKey = item === null ? null : item.key;
            this.$bvModal.show('ModalPriceOptions');
        },
        // <-- calculating -->
        calculating() {
            let currency = this.getCurrency;
            let tax_rate = this.tax_rate;
            let price_option = this.record.price;
            // <--  -->
            let quantity, unit, price_per_item;
            let wsp, wspt;
            let c_percent, c_currency;
            let rp, rpt;
            this.record.pas.forEach((value, index) => {
                // <-- calculate wsp -->
                if (price_option.wsp_option === null) {
                    // <-- rp, rpt -->
                    rp = 0;
                    rpt = 0;
                    switch (this.record.price.rp_option) {
                        case 1:
                            rp = this.record.pas[index].rp;
                            rpt = rp;
                            // <-- rpt -->
                            rpt = this.isTenantTaxpayer ? this.__calc_pt(rp, tax_rate) : rp;
                            this.record.pas[index].rpt = rpt;
                            break;
                        case 2:
                            rpt = this.record.pas[index].rpt;
                            // <-- rp -->
                            rp = this.__calc_p(rpt, tax_rate);
                            this.record.pas[index].rp = rp;
                            break;
                        default:
                            return;
                    }
                    // <-- calculating -->
                    switch (price_option.c_option) {
                        case 1:
                            this.calc_wsp_cp(index, tax_rate, rp);
                            break;
                        case 2:
                            this.calc_wsp_cc(index, tax_rate, rp);
                            break;
                    }
                }
                // <-- calculate commission -->
                else if (price_option.c_option === null) {
                    // <-- wsp, wspt -->
                    wsp = 0;
                    wspt = 0;
                    switch (this.record.price.wsp_option) {
                        case 1:
                            wsp = this.record.pas[index].wsp;
                            // <-- wspt -->
                            wspt = this.isSupplierTaxpayer ? this.__calc_pt(wsp, tax_rate) : wsp;
                            this.record.pas[index].wspt = wspt;
                            break;
                        case 2:
                            wspt = this.record.pas[index].wspt;
                            // <-- wsp -->
                            wsp = this.__calc_p(wspt, tax_rate);
                            this.record.pas[index].wsp = wsp;
                            break;
                        default:
                            return;
                    }
                    // <-- rp, rpt -->
                    rp = 0;
                    rpt = 0;
                    switch (this.record.price.rp_option) {
                        case 1:
                            rp = this.record.pas[index].rp;
                            // <-- rpt -->
                            rpt = this.isTenantTaxpayer ? this.__calc_pt(rp, tax_rate) : rp;
                            this.record.pas[index].rpt = rpt;
                            break;
                        case 2:
                            rpt = this.record.pas[index].rpt;
                            // <-- rp -->
                            rp = this.__calc_p(rpt, tax_rate);
                            this.record.pas[index].rp = rp;
                            break;
                        default:
                            return;
                    }
                    // calculating
                    this.calc_c(index, wsp, wspt, rp);
                }
                // <-- calculate rp -->
                else if (price_option.rp_option === null) {
                    // <-- wsp, wspt -->
                    wsp = 0;
                    wspt = 0;
                    switch (this.record.price.wsp_option) {
                        case 1:
                            wsp = this.record.pas[index].wsp;
                            // <-- wspt -->
                            wspt = this.isSupplierTaxpayer ? this.__calc_pt(wsp, tax_rate) : wsp;
                            this.record.pas[index].wspt = wspt;
                            break;
                        case 2:
                            wspt = this.record.pas[index].wspt;
                            // <-- wsp -->
                            wsp = this.__calc_p(wspt, tax_rate);
                            this.record.pas[index].wsp = wsp;
                            break;
                        default:
                            return;
                    }
                    // <-- calculating -->
                    switch (price_option.c_option) {
                        case 1:
                            this.calc_rp_cp(index, tax_rate, wsp);
                            break;
                        case 2:
                            this.calc_rp_cc(index, tax_rate, wsp);
                            break;
                    }
                }
                // <-- calculate ppi -->
                this.calc_ppi(index);
            });
        },
        // <-- calculate whole sale price -->
        calc_wsp_cp(index, tax_rate, rp) {
            let cp = this.record.pas[index].cp;
            // <-- calculating -->
            let wsp = 0;
            let wspt = 0;
            // <-- taxpayerSupplier: true; taxpayerTenant: false -->
            if (this.isSupplierTaxpayer && !this.isTenantTaxpayer) {
                let wspx = this.__calc_wspx_cp(rp, cp, tax_rate);
                wsp = wspx.wsp;
                wspt = wspx.wspt;
            } else {
                wsp = this.__calc_wsp_cp(rp, cp);
                wspt = this.__calc_rp_cp(wsp, tax_rate);
            }
            // <-- cc -->
            let cc = rp - wspt;
            // <--  -->
            this.record.pas[index].wsp = wsp;
            this.record.pas[index].wspt = wspt;
            this.record.pas[index].cc = cc;
        },
        calc_wsp_cc(index, tax_rate, rp) {
            let cc = this.record.pas[index].cc;
            // <-- calculating -->
            let wsp = 0;
            let wspt = 0;
            // <-- taxpayerSupplier: true; taxpayerTenant: false -->
            if (this.isSupplierTaxpayer && !this.isTenantTaxpayer) {
                let wspx = this.__calc_wspx_cc(rp, cc, tax_rate);
                wsp = wspx.wsp;
                wspt = wspx.wspt;
            } else {
                wsp = this.__calc_wsp_cc(rp, cc);
                wspt = this.__calc_rp_cp(wsp, tax_rate);
            }
            // <-- cp -->
            let cp = this.__calc_cp(rp, cc);
            // <--  -->
            this.record.pas[index].wsp = wsp;
            this.record.pas[index].wspt = wspt;
            this.record.pas[index].cp = cp
        },
        // <-- calculate commission -->
        calc_c(index, wsp, wspt, rp) {
            let c = 0;
            if (this.isSupplierTaxpayer && !this.isTenantTaxpayer) {
                c = this.__calc_c_wsp_rp_tf(wspt, rp);
            } else {
                c = this.__calc_c_wsp_rp(wsp, rp);
            }
            // <--  -->
            this.record.pas[index].cp = c.cp;
            this.record.pas[index].cc = c.cc;
        },
        // <-- calculate retail price -->
        calc_rp_cp(index, tax_rate, wsp) {
            // <-- cp -->
            let cp = this.record.pas[index].cp;
            // <-- cc -->
            // let cc = wsp * cp / 100;
            let cc = this.__calc_cc(wsp, cp);
            // <-- rp -->
            let rp = this.__calc_rp_cp(wsp, cp);
            // <-- rpt -->
            let rpt = this.__calc_rp_cp(rp, tax_rate);
            // <-- taxpayerSupplier: true; taxpayerTenant: false -->
            if (this.isSupplierTaxpayer && !this.isTenantTaxpayer) {
                rp = rpt;
            }
            // <-- taxpayerSupplier === taxpayerTenant or  taxpayerSupplier: false; taxpayerTenant: true -->
            this.record.pas[index].cc = cc;
            this.record.pas[index].rp = rp;
            this.record.pas[index].rpt = rpt;
        },
        calc_rp_cc(index, tax_rate, wsp) {
            // <-- calculating -->
            let cc = this.record.pas[index].cc;
            // <-- rp -->
            let rp = this.__calc_rp_cc(wsp, cc);
            // <-- rpt -->
            let rpt = this.__calc_rp_cp(rp, tax_rate);
            // <-- cp -->
            let cp = this.__calc_cp(rp, cc);
            // <-- taxpayerSupplier: true; taxpayerTenant: false -->
            if (this.isSupplierTaxpayer && !this.isTenantTaxpayer) {
                rp = rpt;
            }
            // <-- taxpayerSupplier === taxpayerTenant or  taxpayerSupplier: false; taxpayerTenant: true -->
            this.record.pas[index].rp = rp;
            this.record.pas[index].rpt = rpt;
            this.record.pas[index].cp = cp;
        },
        // <-- calculate price per item -->
        calc_ppi(index) {
            let quantity = this.record.pas[index].quantity;
            let rpt = this.record.pas[index].rpt;
            // <--  -->
            let ppi = this.__calc_ppi(quantity, rpt);
            this.record.pas[index].ppi = ppi;
        },
        // <-- get functions -->
        getUnit(unit_id) {
            if (unit_id === null) {
                return this.$tc('trade.product.unit', 2);
            }
            // <--  -->
            let unit = this.$tc('trade.product.unit', 2);
            this.getBasicUnits.find((n) => {
                if (n.value === unit_id) {
                    unit = n.mark;
                }
            });
            // <--  -->
            return unit;
        },
    },
    computed: {
        tableFields() {
            let fields = [];
            this.table.fields.forEach(field => {
                fields.push(field);
            });
            return fields;
        },
        // <-- miscellaneous -->
        isTableRowDeleteEnable() {
            return this.record.pas.length > 1;
        },
        // <--  -->
        getBasicUnits() {
            let array = [];
            // <-- check data -->
            if (this.__isNull(this.basic_units)) {
                array.push(
                    {
                        text: this.$t('form.choose_placeholder'),
                        value: null,
                        mark: null
                    });
                return array;
            }
            // <--  -->
            let items = this.basic_units;
            let text = null;
            let mark;
            items.forEach(item => {
                text = item.meta.name;
                // <-- locale -->
                if (item.locale[this.$i18n.locale] !== undefined) {
                    if (!this.__isNull(item.locale[this.$i18n.locale].name)) {
                        text = item.locale[this.$i18n.locale].name;
                        mark = item.locale[this.$i18n.locale].mark;
                    }
                }
                // <-- push -->
                array.push({
                    text: text,
                    value: item.id,
                    mark: mark,
                });
            });
            // <-- add placeholder on top -->
            array.unshift(
                {
                    text: this.$t('form.choose_placeholder'),
                    value: null
                });
            // <--  -->
            return array;
        },
        getPackings() {
            let array = [];
            // <-- check data -->
            if (this.__isNull(this.packings)) {
                array.push(
                    {
                        text: this.$t('form.choose_placeholder'),
                        value: null
                    });
                return array;
            }
            // <--  -->
            let items = this.packings;
            let text = null;
            items.forEach(item => {
                text = item.meta.name;
                // <-- locale -->
                if (item.locale[this.$i18n.locale] !== undefined) {
                    if (!this.__isNull(item.locale[this.$i18n.locale].name)) {
                        text = item.locale[this.$i18n.locale].name;
                    }
                }
                // <-- push -->
                array.push({
                    text: text,
                    value: item.id
                });
            });
            // <-- add placeholder on top -->
            array.unshift(
                {
                    text: this.$t('form.choose_placeholder'),
                    value: null
                });
            // <--  -->
            return array;
        },
        // <--  -->
        getCurrency() {
            return '€';
        },
        // <-- validity -->
        pasValidity() {
            if (this.tabValidity === undefined || this.tabValidity.check === false) {
                return null;
            }
            // <--  -->
            let validity = null;
            this.record.pas.forEach(row => {
                Object.keys(row).find((key) => {
                    if (row[key] === null || row[key] === '') {
                        validity = false;
                    }
                });
            });
            // <--  -->
            this.$set(this.tabValidity, 'pas', validity === null ? true : validity);
            return validity;
        },
    },
    filters: {},
    watch: {
        'record': {
            handler: function (value, oldValue) {
                this.calculating();
            },
            deep: true
        },
        'tax_rate': {
            handler: function (value, oldValue) {
                this.calculating();
            },
            deep: true
        },
        'organisation_id': {
            handler: function (value, oldValue) {
                this.changeSupplier();
                // this.calculating();
            },
            deep: true
        },

    }
}
</script>

<style scoped>

</style>
