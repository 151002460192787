<template>
    <div class="table-detail text-left bg-secondary-light p-2">
        <!-- organisation -->
        <b-card :sub-title="$tc('organisation.organisation', 1)" body-class="pb-0">
            <b-row>
                <!-- name -->
                <b-col cols="6" lg="3" md="4">
                    <b-form-group
                        :label="getName.label"
                        class="mb-2"
                        label-class="font-weight-bold p-0 m-0"
                        label-size="md"
                    >
                        <div>{{getName.value.name}}</div>
                        <div class="pt-2">{{getName.value.full_name}}</div>
                    </b-form-group>
                </b-col>
                <!-- address -->
                <b-col cols="6" lg="3" md="4">
                    <b-form-group
                        :label="getAddress.label"
                        class="mb-2"
                        label-class="font-weight-bold p-0 m-0"
                        label-size="md"
                    >
                        <div v-if="getAddress.state_id < 0" v-html="getAddress.value"></div>
                        <div v-else-if="getAddress.state_id > 0">
                            <div>{{getAddress.value.street}}</div>
                            <div>{{getAddress.value.post_office}}</div>
                            <div>{{getAddress.value.state}}</div>
                            <div class="mt-1">{{getAddress.value.region}}</div>
                        </div>
                    </b-form-group>
                </b-col>
                <!-- org. type -->
                <b-col cols="6" lg="3" md="4">
                    <b-form-group
                        :label="getOrgType.label"
                        class="mb-2"
                        label-class="font-weight-bold p-0 m-0"
                        label-size="md"
                    >
                        <div v-for="data in getOrgType.value">
                            <div class="text-secondary">{{data.label}}</div>
                            {{data.value}}
                        </div>
                    </b-form-group>
                </b-col>
                <!-- tax type -->
                <b-col cols="6" lg="3" md="4">
                    <b-form-group
                        :label="getTaxType.label"
                        class="mb-2"
                        label-class="font-weight-bold p-0 m-0"
                        label-size="md"
                    >
                        <div v-for="data in getTaxType.value">
                            <div class="text-secondary">{{data.label}}</div>
                            {{data.value}}
                        </div>
                    </b-form-group>
                </b-col>
                <!-- farm -->
                <b-col v-if="!__isNull(getFarm.farm_number) && getFarm.farm_number.length > 0" cols="6" lg="3" md="4">
                    <b-form-group
                        :label="getFarm.label"
                        class="mb-2"
                        label-class="font-weight-bold p-0 m-0"
                        label-size="md"
                    >
                        <div v-for="data in getFarm.value">
                            <div class="text-secondary">{{data.label}}</div>
                            {{data.value}}
                        </div>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-card>
        <!-- contact -->
        <b-card :sub-title="$t('organisation.contact')" body-class="pb-0">
            <b-row>
                <b-col v-for="data in getContact" cols="6" lg="3" md="4">
                    <b-form-group
                        :label="data.label"
                        class="mb-2"
                        label-class="font-weight-bold p-0 m-0"
                        label-size="md"
                    >
                        <div v-for="value in data.value" v-if="value.value_b !== null && value.value_c !== null">
                            <div class="text-secondary font-weight-bolder">{{value.value_a}}</div>
                            <div v-if="value.deprecated">
                                <span class="text-secondary"><del>{{value.value_b}}</del></span>:
                                <del>{{value.value_c}}</del>
                            </div>
                            <div v-else>
                                <span class="text-secondary">{{value.value_b}}</span>:
                                {{value.value_c}}
                            </div>
                        </div>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-card>
        <!-- partnership -->
        <b-card :sub-title="$t('organisation.partnership')" body-class="pb-0">
            <b-row class="mb-2">
                <b-col v-for="data in getPartnership" cols="6" lg="3" md="4">
                    {{data.name}}
                </b-col>
            </b-row>
        </b-card>
        <!-- bank -->
        <b-card :sub-title="$tc('register.bank', 1)" body-class="pb-0">
            <b-row>
                <!-- bank -->
                <b-col cols="6" lg="3" md="4">
                    <b-form-group
                        :label="getBank.label"
                        class="mb-2"
                        label-class="font-weight-bold p-0 m-0"
                        label-size="md"
                    >
                        <div class="text-secondary">{{getBank.value.account}}</div>
                        <div>{{getBank.value.name}}</div>
                        <div class="pt-1">{{getBank.value.state}}</div>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-card>
        <!-- user -->
        <b-card :sub-title="$tc('admin.user.main', 0)" body-class="pb-0">
            <b-row>
                <b-col cols="6" lg="3" md="4">
                    <!-- user email -->
                    <b-form-group
                        :label="$t('user.email')"
                        class="mb-2"
                        label-class="font-weight-bold p-0 m-0"
                        label-size="md"
                    >
                        {{getUser.email}}
                    </b-form-group>
                </b-col>
                <!-- user username -->
                <b-col cols="6" lg="3" md="4">
                    <b-form-group
                        :label="$t('user.username')"
                        class="mb-2"
                        label-class="font-weight-bold p-0 m-0"
                        label-size="md"
                    >
                        {{getUser.username}}
                    </b-form-group>

                </b-col>
            </b-row>
        </b-card>
        <!-- introducing -->
        <b-card :sub-title="$t('form.introduction')" body-class="pb-0">
            <b-row>
                <b-col>
                    <div v-html="getLocaleIntroducing"></div>
                </b-col>
            </b-row>
        </b-card>
        <!-- certificates -->
        <b-card :sub-title="$tc('organisation.certificate', 2)" body-class="pb-0">
            <b-row>
                <b-col v-for="data in getCertificate" cols="6" lg="3" md="4">
                    <b-form-group
                        :label="data.name"
                        class="mb-2"
                        label-class="font-weight-bold p-0 m-0"
                        label-size="md"
                    >
                        <!-- certificate organisation -->
                        <div class="text-secondary font-weight-bolder">{{$t('organisation.certificate_organisation')}}
                        </div>
                        {{data.organisation}}
                        <!-- certificate number -->
                        <div class="text-secondary font-weight-bolder">{{$t('organisation.certificate_number')}}</div>
                        {{data.number}}
                        <!-- certificate expiration -->
                        <div class="text-secondary font-weight-bolder">{{$t('organisation.certificate_expiration')}}
                        </div>
                        {{data.expiration}}
                    </b-form-group>
                </b-col>
            </b-row>
        </b-card>
        <!-- notes -->
        <b-card :sub-title="$t('form.notes')" body-class="pb-0">
            <b-row>
                <b-col>
                    <div v-html="getLocaleNotes"></div>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>

import ShowAdminUser from "@/components/admin/user/ShowAdminUser";

export default {
    name: "ShowAdminOrganisation",
    components: {ShowAdminUser},
    props: {
        content: {
            type: Object,
            default() {
                return {}
            }
        },
        data: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    data() {
        return {}
    },
    mounted() {
    },
    methods: {},
    computed: {
        // <-- organisation -->
        getName() {
            return {
                label: this.$t('organisation.name_organisation'),
                value: this.content.name,
            };
        },
        getAddress() {
            let record = this.content;
            let address = record.address;
            let post_office = record.post_office;
            let region = record.region;
            // <--  -->
            let value = address.state_id < 0
                ? address.full === null ? null : address.full.replace('\n', '<br>')
                : {
                    street: address.street,
                    post_office: post_office === null || post_office === undefined ? null : post_office.post_code + ' ' + post_office.city,
                    state: record.state === undefined ? null : record.state.name,
                    region: region === null || region === undefined || region.meta === undefined ? null : region.meta.name
                }
            // <--  -->
            return {
                state_id: address.state_id,
                label: this.$t('address.address'),
                value: value,
            };
        },
        getContact() {
            let array = [];
            // <--  -->
            let record = this.content.contact;
            Object.keys(record).find((label) => {
                array.push(
                    {
                        label: this.$t(`user.${label}`),
                        value: record[label]
                    },
                );
            });
            // <--  -->
            return array;
        },
        getUser() {
            return this.content.user;
        },
        getOrgType() {
            return {
                label: this.$t('organisation.details'),
                value: [
                    {
                        label: this.$t('organisation.type_organisation'),
                        value: this.content.org_type === null ? null : this.content.org_type.meta.name_full
                    },
                    {
                        label: this.$t('organisation.employees'),
                        value: this.content.extended.employees
                    },
                    {
                        label: this.$t('organisation.liaison'),
                        value: this.content.extended.liaison
                    },
                ]
            }
        },
        getTaxType() {
            return {
                label: this.$t('organisation.tax_type'),
                value: [
                    {
                        label: this.$t('organisation.tax_number'),
                        value: this.content.tax_number
                    },
                    {
                        label: this.$t('organisation.tax_payer'),
                        value: this.content.tax_type === null ? null : this.content.tax_type.meta.name
                    },
                ]
            }
        },
        getPartnership() {
            let array = [];
            // <-- check -->
            if(this.__isNull(this.content.partnership)){
                return array;
            }
            // <--  -->
            let keys = Object.keys(this.content.partnership);
            let name;
            keys.find((key) => {
                if (this.content.partnership[key]) {
                    this.data.partnership_types.find((ps) => {
                        if (ps.id === parseInt(key)) {
                            name = ps.meta.name;
                            // <-- locale -->
                            if (ps.locale[this.$i18n.locale] !== undefined) {
                                if (!this.__isNull(ps.locale[this.$i18n.locale].name)) {
                                    name = ps.locale[this.$i18n.locale].name;
                                }
                            }
                            array.push({
                                name: name
                            });
                        }
                    });
                }
            });
            // <--  -->
            return array;
        },
        getBank() {
            let bank_id = this.content.bank_id;
            let value = {
                name: this.content.bank_account.bank_custom === null ? null : this.content.bank_account.bank_custom.replace('\n', '<br>'),
                account: this.content.bank_account.account,
            }
            // <--  -->
            if (bank_id > 0) {
                value.name = this.content.bank.meta.name;
                value.state = this.__getStateName(this.data.states, this.content.bank.state_id);
            }
            // <--  -->
            return {
                bank_id: bank_id,
                label: this.$t('organisation.bank_account'),
                value: value
            }
        },
        getFarm() {
            let farm = this.content.farm;
            let farm_number = farm.number;
            // <--  -->
            return {
                farm_number: farm_number,
                label: this.$t('organisation.farm_details'), // ASKIT Stojan, a ne bi tule dali farm details? Dvakrat imaš farm_number
                value: [
                    {
                        label: this.$t('organisation.farm_number'),
                        value: farm_number
                    },
                    {
                        label: this.$t('organisation.farm_acreage'),
                        value: farm.acreage === null || this.__isNull(farm.acreage_unit) || farm.acreage_unit.length === 0 ? null : `${farm.acreage} ${farm.Acreage_unit}`
                    },
                ]
            }
        },
        getCertificate() {
            let array = [];
            // <-- check -->
            if (this.content.certificate === null || this.content.certificate.table_values === undefined) {
                return array;
            }
            // <--  -->
            let is_null;
            let record = this.content.certificate.table_values;
            record.forEach((value, index) => {
                if (!(this.__isNull(value.name) && this.__isNull(value.number) && this.__isNull(value.organisation && this.__isNull(value.expiration)))) {
                    array.push({
                        index: value.index,
                        name: value.name,
                        number: value.number,
                        organisation: value.organisation,
                        expiration: this.__convertBaseDateToLocaleDateString(value.expiration)
                    });
                }
            });
            // <--  -->
            array = array.reverse();
            return array;
        },
        getLocaleIntroducing() {
            let locale = this.__getLocaleI18n(this.content.locale);
            // <-- check -->
            if (locale === null || this.__isNull(locale.introduction) || locale.introduction.length === 0) {
                return null;
            }
            return locale.introduction;
        },
        getLocaleNotes() {
            let locale = this.__getLocaleI18n(this.content.locale);
            // <-- check -->
            if (locale === null || this.__isNull(locale.notes) || locale.notes.length === 0) {
                return null;
            }
            return locale.notes;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
