<template>
    <b-modal
        :id="$options.name"
        :cancel-title="$t('form.cancel')"
        :ok-title="$t('form.ok')"
        :title="getTitle"
        centered
        header-bg-variant="primary"
        size="lg"
        @ok="formSave"
        @show="modalOpen"
    >
        <b-form-group
            v-if="record !== null"
            :label="$t('trade.product.price_option')"
            class="mb-0"
            label-class="font-weight-bold pt-0"
            label-size="md"
        >
            <b-form-row>
                <!-- col a -->
                <b-col>
                    <!-- wsp option -->
                    <b-form-group
                        :label="$tc('trade.product.wholesale_price', 1)"
                        class="mb-2 mr-sm-2 mb-sm-0"
                        description=""
                        label-class="mb-0"
                        label-for="offer_type"
                    >
                        <b-form-select
                            id="product_type"
                            v-model="record.wsp_option"
                            :options="getWpOptions"
                            class="mb-2 mr-sm-2 mb-sm-0"
                        ></b-form-select>
                    </b-form-group>
                    <!-- rp option -->
                    <b-form-group
                        :label="$tc('trade.product.retail_price', 1)"
                        class="my-2 mr-sm-2 mb-sm-0"
                        description=""
                        label-class="mb-0"
                        label-for="offer_type"
                    >
                        <b-form-select
                            id="product_type"
                            v-model="record.rp_option"
                            :options="getRpOptions"
                            class="mb-2 mr-sm-2 mb-sm-0"
                        ></b-form-select>
                    </b-form-group>
                </b-col>
                <!-- col b -->
                <b-col>
                    <!-- commission option -->
                    <b-form-group
                        :label="$tc('finance.commission', 1)"
                        class="mb-2 mr-sm-2 mb-sm-0"
                        description=""
                        label-class="mb-0"
                        label-for="tax_rate"
                    >
                        <b-form-select
                            id="tax_rate"
                            v-model="record.c_option"
                            :options="getCommissionOptions"
                            class="mb-2 mr-sm-2 mb-sm-0"
                        ></b-form-select>
                    </b-form-group>
                </b-col>
            </b-form-row>
        </b-form-group>
        <!-- alert error-->
        <!--            v-if="alertVisible"-->
        <b-alert
            :show="validity === false"
            class="mb-0 mt-3"
            dismissible
            variant="danger"
        >
            <div v-html="$t('trade.product.one_option_message')"></div>
        </b-alert>
    </b-modal>
</template>

<script>
export default {
    name: "ModalPriceOptions",
    components: {},
    props: {
        rowKey: null,
        formPrice: {
            type: Object,
            default: null
        },
        isTenantTaxpayer: {
            type: Boolean,
            default: null
        },
        isSupplierTaxpayer: {
            type: Boolean,
            default: null
        },
    },
    data() {
        return {
            record: null,
            validity: null,
        }
    },
    mounted() {
    },
    methods: {
        modalOpen() {
            this.validity = null;
            this.record = this.__clone(this.formPrice)
        },
        hideModal() {
            // <-- hide the modal manually -->
            this.$nextTick(() => {
                this.$bvModal.hide(this.$options.name);
            });
        },
        // <--  -->
        checkValidity() {
            this.validity = null;
            let count = 0;
            // <-- count -->
            Object.keys(this.record).forEach(key => {
                if (this.record[key] === null) {
                    count++;
                }
            });
            // <--  -->
            this.validity = count === 1;
            return this.validity;
        },
        formSave(bvModalEvent) {
            bvModalEvent.preventDefault();
            // <--  -->
            if (!this.checkValidity()) {
                return;
            }
            // <--  -->
            Object.keys(this.record).forEach(key => {
                this.$set(this.formPrice, key, this.record[key]);
            });
            // <--  -->
            this.hideModal();
        },
    },
    computed: {
        getTitle() {
            // <-- for table -->
            if (this.rowKey === null) {
                return this.$tc('trade.product.price_option_title', 2);
            }
            // <-- for row -->
            return this.$tc('trade.product.price_option_title', 1);
        },
        // <--  -->
        getWpOptions() {
            switch (this.isSupplierTaxpayer) {
                // <-- with tax -->
                case true:
                    return [
                        {
                            text: this.$t('form.calculating'),
                            value: null
                        },
                        {
                            text: this.__capitalize(this.$t('finance.input_price_without_tax')),
                            value: 1
                        },
                        {
                            text: this.__capitalize(this.$t('finance.input_price_with_tax')),
                            value: 2
                        },
                    ];
                // <-- without tax -->
                case false:
                    return [
                        {
                            text: this.$t('form.calculating'),
                            value: null
                        },
                        {
                            text: this.__capitalize(this.$t('finance.input_price')),
                            value: 1
                        },
                    ];
                default:
                    return [
                        {
                            text: this.$t('form.calculating'),
                            value: null
                        },
                    ];
            }
        },
        getCommissionOptions() {
            return [
                {
                    text: this.$t('form.calculating'),
                    value: null
                },
                {
                    text: this.$t('finance.input_commission') + ' (%)',
                    value: 1
                },
                {
                    text: this.$t('finance.input_commission') + ' (€)',     // TODO change to tenant currency
                    value: 2
                },
            ]
        },
        getRpOptions() {
            switch (this.isTenantTaxpayer) {
                // <-- with tax -->
                case true:
                    return [
                        {
                            text: this.$t('form.calculating'),
                            value: null
                        },
                        {
                            text: this.__capitalize(this.$t('finance.input_price_without_tax')),
                            value: 1
                        },
                        {
                            text: this.__capitalize(this.$t('finance.input_price_with_tax')),
                            value: 2
                        },
                    ];
                // <-- without tax -->
                case false:
                    return [
                        {
                            text: this.$t('form.calculating'),
                            value: null
                        },
                        {
                            text: this.__capitalize(this.$t('finance.input_price')),
                            value: 1
                        },
                    ];
                default:
                    return [
                        {
                            text: this.$t('form.calculating'),
                            value: null
                        },
                    ];
            }
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
